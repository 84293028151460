import { Configuration } from "cometd";
import { sortBy } from "lodash";
import { IChat, IChatMessage, IChatTranscript } from "online-services-types";
import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { IUploadedFile } from "src/components/AttachmentUploader/AttachmentUploaderComponent";
import { ChatAttachmentInfo, ChatMessageWithPending } from "src/services/atrs/atrs.types";

interface IChatSupportContext {
  userId: string;
  messages: ChatMessageWithPending[];
  sendMessage: (text: string) => void;
  attachFile: (file: IUploadedFile) => void;
  deleteFile: (fileId: string) => void;
  reloadChats: () => void;
  showChatButton: boolean;
  isChatAppOpen: boolean;
  setIsChatAppOpen: Dispatch<SetStateAction<boolean>>;
  chats: IChat[];
  selectedChat: IChat | null;
  setSelectedChat: Dispatch<SetStateAction<IChat | null>>;
  isLoadingChats: boolean;
  isLoadingMessages: boolean;
  isChatOpen: boolean;
}

export const ChatSupportContext = createContext<IChatSupportContext>({
  userId: "",
  messages: [],
  sendMessage: () => undefined,
  attachFile: () => undefined,
  deleteFile: () => undefined,
  reloadChats: () => undefined,
  showChatButton: false,
  isChatAppOpen: false,
  setIsChatAppOpen: () => undefined,
  chats: [],
  selectedChat: null,
  setSelectedChat: () => undefined,
  isLoadingChats: false,
  isLoadingMessages: false,
  isChatOpen: false,
});

export const useChatSupport = () => useContext(ChatSupportContext);

export const getChatConfiguration = (): Configuration => {
  const token = localStorage.getItem("token") || "";

  return {
    url: `${process.env.REACT_APP_API_URL_BASE}requests/atrs-chat/`,
    requestHeaders: {
      Authorization: token,
      "Content-Type": "application/json",
    },
    maxNetworkDelay: 50000,
    appendMessageTypeToURL: false,
  };
};

export const getChatAttachmentInfoFromJson = (json: string): ChatAttachmentInfo | null => {
  try {
    const jsonObject = JSON.parse(json);
    const attachmentInfo: ChatAttachmentInfo = {
      name: jsonObject.name,
      documentId: jsonObject.documentId,
      contentVersionId: jsonObject.contentVersionId,
      transcriptId: jsonObject.transcriptId,
      fileDate: jsonObject.fileDate,
      downloadUrl: jsonObject.downloadUrl,
    };
    return attachmentInfo.name && (attachmentInfo.downloadUrl || attachmentInfo.contentVersionId)
      ? attachmentInfo
      : null;
  } catch (e) {
    return null;
  }
};

export const groupTranscriptsByCase = (transcripts: IChatTranscript[]): IChat[] => {
  const transcriptMap: { [caseId: string]: IChatTranscript[] } = transcripts.reduce((map, transcript) => {
    if (map[transcript.caseId]) {
      map[transcript.caseId].push(transcript);
    } else {
      map[transcript.caseId] = [transcript];
    }
    return map;
  }, {});
  return Object.entries(transcriptMap).map(([caseId, transcripts]) => ({
    caseId: caseId,
    caseNumber: transcripts[0]?.caseNumber,
    transcripts: sortBy(transcripts, "createdDate"),
  }));
};

export const closeChat = (chat: IChat) => ({
  ...chat,
  transcripts: (chat.transcripts || []).map((transcript) => ({
    ...transcript,
    status: "Closed",
  })),
});

export const isMessageMatch = (
  oldMessage: ChatMessageWithPending,
  newMessage: IChatMessage,
  pendingId?: number
): boolean => {
  if (!oldMessage.isPending) return oldMessage.id === newMessage.id;
  if (oldMessage.messageType !== newMessage.messageType) return false;

  switch (oldMessage.messageType) {
    case "Text":
      return Boolean(oldMessage.pendingId) && oldMessage.pendingId === pendingId;
    case "Attachment":
      return newMessage.content.includes(oldMessage.id);
    default:
      return false;
  }
};

export const isSessionOpen = (transcript: IChatTranscript) =>
  transcript.sessionStatus === "Online" && transcript.status === "Open";
export const isChatOpen = (chat: IChat) => chat.transcripts?.some(isSessionOpen);

function* tempIdGenerator() {
  let index = 1;
  while (true) {
    yield index++;
  }
}

const tempId = tempIdGenerator();
export const getNextTempId = () => tempId.next().value as number;
