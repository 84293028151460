import "react-toastify/dist/ReactToastify.css";
import "src/components/ErrorToast/react-toastify-overrides.css";

import React from "react";
import { ChangePasswordDialog, PASSWORD_NOTIFICATION_LOCALSTORAGE } from "./ChangePasswordDialog";
import { ChatWindow, ChatSupportProvider } from "src/services/atrs/atrs.components";
import { IAccount, IEquipment, IInfoJWTData, INewsItem, IUserInfo, Service } from "online-services-types";
import { IExtendedStep, IOnboardingDispatchProps, getRouteOfTargetStep } from "src/redux/onboarding";
import {
  IRouteWithComponent,
  getAvailableRoutes,
  getCurrentRoute,
  getRoutes,
  history,
  shouldRedirectToLogin,
} from "src/routes";
import { InformationIcon, UserIcon } from "src/icons";
import Joyride, { ACTIONS, CallBackProps, EVENTS, LIFECYCLE, STATUS } from "react-joyride";
import { Link, Route, Router, Switch } from "react-router-dom";
import { NotificationContext, NotificationsDataRefresh } from "src/models/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { TOKEN_EXPIRATION_REMINDER_TIME, TOKEN_EXPIRATION_TIME } from "src/redux/auth";
import { breakpoints, whenTabletOrLarger } from "src/design-system/Tokens/breakpoints";
import defaultStyles, { GlobalDefaultStyle } from "src/design-system/Theme/defaultStyles";
import { displayCustom, displaySuccess } from "src/util/error";
import { get, isNull, isUndefined, keys } from "lodash";
import { getSelectedColorTheme, setSelectedColorTheme } from "src/util/localstorage";
import {
  getUserInitialInfo,
  getUserProfile,
  patchUserHasConfirmedUserDetails,
  patchUserHasReadEULA,
} from "src/views/ProfileView/userProfile";
import { outerContainerId, pageWrapId } from "../UserPrefs/containerIds";
import { setSector, translateString } from "src/util/localization";
import styled, { ThemeProvider } from "styled-components";
import { useHeartbeat, useHtmlElementClassObserver } from "src/components/App/hooks";
import { APIFetch } from "src/APIFetch";
import { State as BurgerMenuState } from "react-burger-menu";
import { Button } from "src/design-system/Button";
import { ConfirmInformationDialog } from "src/components/ConfirmInformationDialog";
import { Contact } from "../Contact";
import { Container } from "src/design-system/Container";
import { EULA } from "src/components/EULA";
import { ErrorScreen } from "./ErrorScreen";
import { GTM } from "../GTM";
import { H1 } from "src/design-system/Tokens/typography";
import { Header } from "../Header";
import { IUserState } from "src/redux/user";
import { LinkWrapper } from "src/design-system/Link";
import { LoadingSpinner } from "../LoadingSpinner";
import { LocalizedString } from "../Localization";
import { Modal } from "src/components/Dialog/Modal";
import { NewsModal } from "src/components/NewsModal";
import { NotificationSideBar } from "../NotificationSideBar";
import { RedirectToIndex } from "./RedirectToIndex";
import { ReleaseNotesSidebar } from "src/views/ReleaseNotesView/ReleaseNotesSidebar";
import { RouteId } from "src/util/routeId";
import { RouteType } from "src/routeType";
import { ScrollToTop } from "../ScrollToTop";
import { ToastContainer } from "react-toastify";
import { Tooltip } from "../Onboarding";
import { UserPrefs } from "../UserPrefs";
import { VersionContextWrapper } from "src/views/ReleaseNotesView/VersionContext";
import colors from "src/design-system/Tokens/colors";
import { datadogRum } from "@datadog/browser-rum";
import { fetchPartsBlockedStatus } from "src/util/fetchPartsBlockedStatus";
import { getAppEnvironment } from "src/util/init";
import { getRouteWithComponent } from "src/routeComponents";
import { handleClientError } from "src/util/handleClientError";
import { handleJWTOnLogin } from "./jwtHandling";
import { inputTokens } from "src/design-system/Tokens/tokens";
import { logPageView } from "src/util/viewlog";
import { openingAnimationDuration } from "src/design-system/Tokens/animations";
import { setGlobalDispatch } from "src/redux/globalDispatch";
import { themes } from "src/design-system/Theme/theme";
import { useLocalStorage } from "usehooks-ts";
import { v4 as uuid } from "uuid";

const AppBase = styled.div`
  ${defaultStyles}
  height: 100%;
  padding: ${inputTokens.pageMargin};
`;

const MainContainer = styled.div`
  min-height: calc(100vh - 70px - 5.5em);
  margin: 2em 4% 0;
  padding-bottom: 3.5em;

  ${whenTabletOrLarger(`
    min-height: calc(100vh - 100px - 8em);
    margin-top: 3em;
    padding-bottom: 5em;
  `)};
`;

const OuterWrapper = styled.div`
  height: 100%;
`;

const InnerWrapper = styled.div`
  height: 100%;
`;

const ReadOnlyBottomIndicator = styled.div`
  height: 10px;
  width: 100%;
  background-color: ${colors.notification.error};
  position: fixed;
  bottom: 0;
`;

const PunchOutBottomIndicator = styled.div`
  height: 10px;
  width: 100%;
  background-color: #2f8fff;
  position: fixed;
  bottom: 0;
`;

const LinkButton = styled(LinkWrapper)`
  font-size: 0.85rem;
`;

interface IExpirationModal {
  children: React.ReactNode;
}

export const ExpirationModal = styled(Modal)<IExpirationModal>`
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.transparent.black};
    z-index: 15;
  }

  &__content {
    position: absolute;
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    padding: 30px 15px 20px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    &__content {
      top: 50%;
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
      bottom: auto;
      padding: 30px 40px 10px;
      max-height: calc(100% - 150px);
    }
  }
`;

export const ExpirationModalInnerWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 100px;

  & button {
    margin-top: 20px;
  }

  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: row;
  }
`;

export interface IAppDispatchProps extends IOnboardingDispatchProps {
  dispatch(): any;
  fetchNotifications(refreshRequests?: NotificationsDataRefresh): void;
  clearNotifications(): void;
  setUserInfo(userInfo?: IUserInfo): void;
  setApplicationContext(contextData: IInfoJWTData): void;
  setPartsBlockingStatus(blocked: boolean): void;
  setSelectedInstallation(selectedInstallationId?: string, selectedInstallationName?: string): void;
  getNews(): void;
  selectCurrentAccount(id: string): void;
  getEquipments: () => void;
}

export interface IReduxStateProps {
  tokenExpirationDate: number;
  isSessionExpired: boolean;
  selectedInstallationName: string;
  selectedInstallationId: string;
  services: ReadonlyArray<Service>;
  readonly: boolean;
  punchOut: boolean;
  currentUserEmail: string;
  isOnboardingFinished: boolean;
  isOnboardingRunning: boolean;
  onboardingStep: number;
  onboardingSteps: IExtendedStep[];
  onboardingComponent?: React.Component;
  userInfo: IUserState;
  accounts: IAccount[];
  isEnergyConsultant: boolean;
  isDesigner: boolean;
  punchOutContact: string;
  isQuantipartsDistributor: boolean;
  isQPDistributorViewDisabled: boolean;
  isShipyard: boolean;
  equipments: IEquipment[];
  news: INewsItem[];
}

export type IAppProps = IReduxStateProps & IAppDispatchProps;

interface IErrorState {
  hasError: boolean;
  errorUUID?: string;
  errorName?: string;
  errorStack?: string;
}

class ErrorBoundary extends React.Component<
  { onError: (state: IErrorState) => void; rumInitiated: boolean; children?: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { onError: (state: IErrorState) => void; rumInitiated: boolean }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  /**
   *  Global react error catcher.
   *  Catches all errors in this component and all child components.
   *  Do not remove!
   */
  public componentDidCatch = (error: Error, errorInfo: React.ErrorInfo) => {
    const errorName = error.name;
    const errorUUID: string = uuid();
    const errorStack = error.stack;
    this.props.onError({ hasError: this.state.hasError, errorUUID, errorName, errorStack });
    handleClientError(errorInfo, error.message, errorUUID);

    if (this.props.rumInitiated && this.state.hasError) {
      const datadogError = new Error(error.message);
      datadogError.name = "ReactRenderingError";
      datadogError.stack = errorInfo.componentStack;
      datadogError.cause = error;
      datadogRum.addError(datadogError);
    }
  };

  render() {
    return this.props.children;
  }
}

const useUserProfile = () => {
  const [loading, setLoading] = React.useState(true);
  const [eulaAccepted, setEULAAccepted] = React.useState<boolean>(false);
  const [userDetailsConfirmed, setUserDetailsConfirmed] = React.useState<boolean>(false);
  const [userDetailsUpdated, setUserDetailsUpdated] = React.useState<boolean>(false);
  const [initialUserDetails, setInitialUserDetails] = React.useState({ title: "", department: "" });

  React.useEffect(() => {
    const getUserStatus = async () => {
      return await getUserProfile(false);
    };

    getUserStatus().then((user) => {
      setLoading(false);
      setEULAAccepted(user?.hasAcceptedEULA ?? false);
      // TODO: In the follow-up story, use a new field for this. This should just be visible for new users after the EULA at the moment
      setUserDetailsConfirmed(user?.hasAcceptedEULA ?? false);
      setUserDetailsUpdated(!user?.isDetailsUpdateNeeded ?? true);
      setInitialUserDetails({ title: user?.title ?? "", department: user?.department ?? "" });
    });
  }, []);

  const acceptEULA = async () => {
    setEULAAccepted(true);
    await patchUserHasReadEULA();
  };

  const confirmUserDetails = async (title: string, department: string) => {
    setUserDetailsConfirmed(true);
    setUserDetailsUpdated(true);
    await patchUserHasConfirmedUserDetails(title, department);
    setInitialUserDetails({ title, department });
  };

  const dismissUserDetails = async () => {
    setUserDetailsConfirmed(true);
    setUserDetailsUpdated(true);
  };

  const eulaOpened = !eulaAccepted && !loading;
  const userDetailsOpened = eulaAccepted && (!userDetailsConfirmed || !userDetailsUpdated) && !loading;
  return {
    eulaAccepted,
    acceptEULA,
    eulaOpened,
    initialUserDetails,
    userDetailsConfirmed,
    userDetailsUpdated,
    confirmUserDetails,
    dismissUserDetails,
    userDetailsOpened,
    loading,
  };
};

let fullTimer: number;
let tenMinTimer: number;
let notificationTimer: number;
const notificationUpdateInterval = 300000;
let isOnboardingResumedInStepBefore = false;

export const AppComponent = (props: IAppProps) => {
  const userProfile = useUserProfile();
  const [automaticTranslationFound, setTranslationFound] = React.useState(false);
  const [rumInitiated, setRum] = React.useState(false);
  const [isLoggedIn, setLoggedIn] = React.useState(false);
  const [contactOpen, setContactOpen] = React.useState(false);
  const [accessToken] = React.useState(localStorage.getItem("token") || "");

  const [isSessionExpirationOpen, setSessionExpirationOpen] = React.useState(false);
  const [isSessionExpirationWarningOpen, setSessionExpirationWarningOpen] = React.useState(false);

  const authRequired = React.useMemo(() => shouldRedirectToLogin(window.location.pathname), []);

  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const toggleMenu = () => setMenuOpen((s) => !s);

  const [isNotificationsOpen, setNotificationsOpen] = React.useState(false);
  const toggleNotifications = () => setNotificationsOpen((s) => !s);
  const onMenuStateChange = React.useCallback(({ isOpen }: BurgerMenuState) => setNotificationsOpen(isOpen), []);

  const htmlElementClassNames = useHtmlElementClassObserver(document.documentElement);
  // Check if the page has been translated via browser extension
  React.useEffect(() => {
    if (htmlElementClassNames.some((className) => className.startsWith("translated"))) {
      setTranslationFound(true);
    }
  }, [htmlElementClassNames]);
  // We must keep the dialog in a separate useEffect to avoid re-render on app initialization which will close dialog
  React.useEffect(() => {
    if (automaticTranslationFound) {
      displayCustom({
        customIcon: InformationIcon,
        message: translateString("user.automaticTranslationNote"),
        content: (
          <LinkButton>
            <Link to="/profile">{translateString("user.profileSettings")}</Link>
          </LinkButton>
        ),
        options: {
          autoClose: false,
        },
      });
    }
  }, [automaticTranslationFound]);

  const [error, setError] = React.useState<{
    hasError: boolean;
    errorUUID?: string;
    errorName?: string;
    errorStack?: string;
  }>({
    hasError: false,
  });

  const [serviceAnnouncements] = useHeartbeat({ enabled: isLoggedIn && !isSessionExpirationOpen });

  React.useEffect(() => {
    if (isLoggedIn) {
      props.getEquipments();
      setGlobalDispatch(props.dispatch);
    }
  }, [isLoggedIn]);

  React.useEffect(() => {
    clearTimeout(tenMinTimer);
    clearTimeout(fullTimer);
    setSessionTimeouts();
  }, [props.tokenExpirationDate]);

  React.useEffect(() => {
    handleJWTOnLogin({
      setSector,
      setUserInfo: props.setUserInfo,
      setUserAsLoggedIn: () => setLoggedIn(true),
      setApplicationContext: props.setApplicationContext,
    });
  }, []);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!isLoggedIn && authRequired) {
      timeout = setTimeout(() => redirectToLogout(accessToken), 3_000);
    }

    return () => clearTimeout(timeout);
  }, [isLoggedIn, authRequired]);

  React.useEffect(() => {
    if (props.accounts.length) {
      const params = Object.fromEntries(new URLSearchParams(window.location.search.replace(new RegExp("^[?]"), "")));

      const currentAccount = props.accounts.find((account) => account.selected);
      const currentAccountId = currentAccount?.id || "";

      if (params.accountId && params.accountId !== currentAccountId) {
        props.selectCurrentAccount(`${params.accountId}`);
      }
    }
  }, [props.accounts.length]);

  // Logout on session timeout
  React.useEffect(() => {
    const now = new Date().getTime();
    let tokenExpiresAt: string | number | null = localStorage.getItem("token_exp_date");
    if (!tokenExpiresAt) return;

    tokenExpiresAt = parseInt(tokenExpiresAt, 10);
    if (now > tokenExpiresAt) {
      clearTimeout(fullTimer);
      clearTimeout(tenMinTimer);
      localStorage.removeItem("info");
      localStorage.removeItem("theme");
      localStorage.removeItem("token");
      localStorage.removeItem("token_exp_date");
      localStorage.removeItem(PASSWORD_NOTIFICATION_LOCALSTORAGE);
      setLoggedIn(false);
    }
  }, []);

  React.useEffect(() => {
    if (!authRequired) return;
    clearTimeout(fullTimer);
    clearTimeout(tenMinTimer);
    setSessionTimeouts();

    window.addEventListener("unload", () => {
      localStorage.setItem("token_exp_date", props.tokenExpirationDate + "");
    });

    if (!isLoggedIn) return;
    if (!props.isShipyard) props.startOnboarding(RouteId.Index);

    fetchPartsBlockedStatus().then((response) => props.setPartsBlockingStatus(response.blocked));
    getUserInitialInfo().then(({ isQuantipartsDistributor, isQPDistributorViewDisabled, daysUntilPasswordExpiry }) => {
      // fetch news & notifications only if not QP distributor account
      props.setUserInfo({ ...props.userInfo, isQPDistributorViewDisabled, daysUntilPasswordExpiry });
      // fetch news only if not QP distributor account
      if (!isQuantipartsDistributor) {
        if (!props.isShipyard) props.getNews();
        setNotificationFetchTimeout();

        // Delay first notification to avoid errors before login
        // Note: leave delay to 1 ms to ensure the fetch at least happens last compared to all initial 0 ms timer triggers etc.
        const notificationDelay = 1;
        setTimeout(() => props.fetchNotifications("all"), notificationDelay);
      }
    });
  }, [authRequired, isLoggedIn]);

  const [openedPasswordModal, setPasswordModal] = React.useState(false);
  const [showPasswordNotification, setShowPasswordNotification] = useLocalStorage(
    PASSWORD_NOTIFICATION_LOCALSTORAGE,
    true
  );
  const expiringPassword =
    !isNull(props.userInfo.daysUntilPasswordExpiry) &&
    !isUndefined(props.userInfo.daysUntilPasswordExpiry) &&
    props.userInfo.daysUntilPasswordExpiry <= 30;
  const isFreshPassword =
    !isNull(props.userInfo.daysUntilPasswordExpiry) &&
    !isUndefined(props.userInfo.daysUntilPasswordExpiry) &&
    props.userInfo.daysUntilPasswordExpiry > 30;

  React.useEffect(() => {
    if (isFreshPassword) {
      setShowPasswordNotification(true);
    }
  }, [isFreshPassword]);

  React.useEffect(() => {
    if (showPasswordNotification && expiringPassword) {
      displayCustom({
        customIcon: UserIcon,
        onClose: () => {
          setShowPasswordNotification(false);
        },
        title: translateString("user.passwordExpiration"),
        message:
          props.userInfo.daysUntilPasswordExpiry && props.userInfo.daysUntilPasswordExpiry > 0
            ? translateString("user.passwordStopWorking", { days: props.userInfo.daysUntilPasswordExpiry })
            : translateString("user.passwordStopWorkingToday"),
        content: (
          <LinkButton onClick={() => setPasswordModal(true)}>{translateString("user.changePassword")}</LinkButton>
        ),
        options: {
          autoClose: false,
        },
      });
    }
  }, [expiringPassword]);

  const trackPageView = () => logPageView(window.location.pathname);

  React.useEffect(() => {
    if (!isLoggedIn) return;
    if (props.readonly) return;
    trackPageView();
    history.listen(trackPageView); // Track all subsequent pageviews
  }, [history, isLoggedIn]);

  React.useEffect(() => {
    props.clearNotifications();
  }, [history.location.pathname]);

  const redirectToLogout = (accessToken: string) =>
    window.location.assign(
      `${process.env.REACT_APP_API_URL_BASE}auth/logout?token=${accessToken}&path=${encodeURIComponent(
        `${window.location.pathname}${window.location.search}`
      )}`
    );

  const setNotificationFetchTimeout = () => {
    if (notificationTimer) clearTimeout(notificationTimer);

    notificationTimer = window.setTimeout(() => {
      if (isLoggedIn && !isSessionExpirationOpen) props.fetchNotifications();
      setNotificationFetchTimeout();
    }, notificationUpdateInterval);
  };

  const setSessionTimeouts = () => {
    tenMinTimer = window.setTimeout(() => {
      setSessionExpirationWarningOpen(true);
      setSessionExpirationOpen(false);
    }, TOKEN_EXPIRATION_REMINDER_TIME);

    fullTimer = window.setTimeout(() => {
      setSessionExpirationWarningOpen(false);
      setSessionExpirationOpen(true);
      localStorage.removeItem("token");
      localStorage.removeItem("info");
    }, TOKEN_EXPIRATION_TIME);
  };

  const acceptSessionExpiration = () => {
    clearTimeout(tenMinTimer);
    clearTimeout(fullTimer);

    setSessionExpirationOpen(false);
    setSessionExpirationWarningOpen(false);

    localStorage.removeItem("token_exp_date");
    localStorage.removeItem(PASSWORD_NOTIFICATION_LOCALSTORAGE);
    redirectToLogout(accessToken);
  };

  // Get theme name from local storage and use that to get theme to be used by ThemeProvider.
  let currentTheme = themes[getSelectedColorTheme()];
  // Set quantiparts Theme
  if (props.isQuantipartsDistributor) {
    if (props.isQPDistributorViewDisabled) {
      setSelectedColorTheme("dark");
      currentTheme = themes.dark;
    } else {
      setSelectedColorTheme("quantiparts");
      currentTheme = themes.quantiparts;
    }
  }

  const resetNotificationFetchTimeout = () => {
    if (notificationTimer) clearTimeout(notificationTimer);

    notificationTimer = window.setTimeout(() => {
      if (isLoggedIn && !isSessionExpirationOpen) props.fetchNotifications();
      resetNotificationFetchTimeout();
    }, 300_000);
  };

  const logout = () => {
    localStorage.removeItem("theme");
    localStorage.removeItem("token");
    localStorage.removeItem("token_exp_date");
    localStorage.removeItem(PASSWORD_NOTIFICATION_LOCALSTORAGE);
    redirectToLogout(accessToken);
  };

  /*
    This function delays the invokation of specified onboarding step to allow eg. animation to happen before the step is shown through setting the state of component.
    It can be used to open eg side menu before showing the side menu items, so that they will be rendered and found by Joyride.
  */
  const onboardingStepCallbacksWithDelay = (
    callBackProps: CallBackProps,
    targetStep: string,
    stepBeforeCallback: () => void,
    stepAfterCallback: () => void,
    delay: number
  ) => {
    const currentRoute = getCurrentRoute();
    const routeIdOfTargetStep = getRouteOfTargetStep(targetStep);

    // Add a dot in front of targetStep so that it will match the format of Joyride target property
    if (currentRoute && currentRoute.id === routeIdOfTargetStep && callBackProps.step.target === "." + targetStep) {
      if (callBackProps.type === EVENTS.STEP_BEFORE) {
        if (!isOnboardingResumedInStepBefore) {
          props.pauseOnboarding();
          stepBeforeCallback();
          setTimeout(() => {
            props.resumeOnboarding();
            isOnboardingResumedInStepBefore = true;
          }, delay);
        }
      } else if (callBackProps.type === EVENTS.STEP_AFTER) {
        props.pauseOnboarding();
        stepAfterCallback();
        setTimeout(() => {
          const isOnboardingFinishedOrSkippedOrClosed =
            callBackProps.status === STATUS.FINISHED ||
            callBackProps.status === STATUS.SKIPPED ||
            callBackProps.action === ACTIONS.CLOSE;
          // If onboarding is not finished or is not skipped then keep resuming. When it is finished, don't resume.
          if (!isOnboardingFinishedOrSkippedOrClosed) {
            props.resumeOnboarding();
          }
          isOnboardingResumedInStepBefore = false;
        }, delay);
      }
    }
  };

  const onboarding = (callBackProps: CallBackProps) => {
    let currentStep = props.onboardingSteps[props.onboardingStep];

    const refreshCurrentStep = () => (currentStep = props.onboardingSteps[props.onboardingStep]);

    if (callBackProps.type === EVENTS.STEP_AFTER) {
      if (callBackProps.action === ACTIONS.NEXT) {
        props.incrementOnboardingStep();
        refreshCurrentStep();
        if (currentStep && currentStep.onStepNext) {
          currentStep.onStepNext(props.onboardingComponent);
        }
      } else if (callBackProps.action === ACTIONS.PREV) {
        props.decrementOnboardingStep();
        refreshCurrentStep();
        if (currentStep && currentStep.onStepPrevious) {
          currentStep.onStepPrevious(props.onboardingComponent);
        }
      } else if (callBackProps.action === ACTIONS.CLOSE) {
        refreshCurrentStep();
        if (currentStep && currentStep.onStepNext) {
          currentStep.onStepNext(props.onboardingComponent);
        }
      }
    }

    if (callBackProps.status === STATUS.SKIPPED) {
      props.stopOnboarding(true);
    } else if (callBackProps.action === ACTIONS.CLOSE && callBackProps.lifecycle === LIFECYCLE.COMPLETE) {
      props.stopOnboarding(false);
    }

    // NOTE: the function onboardingStepCallbacksWithDelay() can be further improved so that you can control variables needed in other views in one place(redux object in onboarding reducer?)
    // Now it uses this component's state (property isContactOpen) to operate.
    onboardingStepCallbacksWithDelay(
      callBackProps,
      "main-onboarding-ready",
      () => setContactOpen(true),
      () => setContactOpen(false),
      openingAnimationDuration
    );

    if (callBackProps.type === EVENTS.STEP_BEFORE) {
      refreshCurrentStep();
      if (currentStep && currentStep.onStepActivate) {
        currentStep.onStepActivate(props.onboardingComponent);
      }
    }

    scrollToStepElementIfNeeded(callBackProps);
  };

  const scrollToStepElementIfNeeded = (callBackProps: CallBackProps) => {
    const currentStepElements = document.getElementsByClassName((callBackProps.step.target as string).slice(1));

    if (currentStepElements && currentStepElements.length > 0) {
      const currentStepElement = currentStepElements[0];
      const boundingRect = currentStepElement.getBoundingClientRect();
      const rectTop = boundingRect.top;
      const inViewport = Math.abs(rectTop) < window.innerHeight;

      // Check if current step element is visible, if not, then scroll to it
      if (!inViewport) {
        currentStepElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  const buildRoute = (route: IRouteWithComponent) => (
    <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
  );

  const [selectedAccount] = props.accounts.filter((account) => account.selected);
  const hasRunningHoursEngines = props.equipments.filter((equipment) => equipment.isRunningHoursEngine).length > 0;
  const views = getAvailableRoutes(
    props.services,
    props.userInfo.isManager,
    props.isEnergyConsultant,
    props.isDesigner,
    selectedAccount,
    hasRunningHoursEngines
  )
    .map(getRouteWithComponent)
    .map(buildRoute);

  const standAloneRoutes = getRoutes(RouteType.Standalone);
  const standAloneViews = keys(standAloneRoutes)
    .map((key) => get(standAloneRoutes, key))
    .map(getRouteWithComponent)
    .map(buildRoute);

  const refreshSession = async () => {
    // this call to salesforce is to reset the expiration date on salesforce side, it can basically be any call to salesfoce
    // previously tried getUserInfo action, but apparently 'v1/user' endpoint does not exist on salesforce side
    await new APIFetch("service/addresses").get();

    clearTimeout(fullTimer);
    clearTimeout(tenMinTimer);

    setSessionTimeouts();

    setSessionExpirationOpen(false);
    setSessionExpirationWarningOpen(false);

    localStorage.removeItem("token_exp_date");
  };

  React.useEffect(() => {
    const env = getAppEnvironment();
    if (!rumInitiated) {
      const DATADOG_SITE = "datadoghq.eu";
      const DATADOG_SERVICE = "wartsila-online";
      const DATADOG_APP_ID = process.env.REACT_APP_DATADOG_APP_ID!;
      const DATADOG_PROXY_HOST = process.env.REACT_APP_DATADOG_PROXY_HOST!;
      const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN!;

      datadogRum.init({
        env,
        site: DATADOG_SITE,
        trackResources: true,
        trackLongTasks: true,
        sessionSampleRate: 100,
        trackInteractions: true,
        service: DATADOG_SERVICE,
        trackUserInteractions: true,
        proxyUrl: DATADOG_PROXY_HOST,
        sessionReplaySampleRate: 100,
        beforeSend: (event) => {
          if (event.type === "error") {
            const messagesToIgnore = ["Session expired or invalid", "Error during service worker registration"];
            const ignored = messagesToIgnore.some((message) => event.error.message.includes(message));
            if (ignored) return false;
          }

          return true;
        },
        applicationId: DATADOG_APP_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        defaultPrivacyLevel: "mask-user-input",
        version: process.env.REACT_APP_OS_VERSION,
        allowedTracingOrigins: [process.env.REACT_APP_API_URL_BASE!],
      });

      datadogRum.setUser({
        name: props.userInfo.name,
        email: props.userInfo.email,
      });

      datadogRum.startSessionReplayRecording();

      setRum(true);
    }
  }, [rumInitiated, props.userInfo]);

  if (isLoggedIn && props.isSessionExpired) {
    return (
      <ExpirationModal isOpen={true}>
        <ExpirationModalInnerWrapper>
          <h2>
            <LocalizedString id="auth.sessionExpired" />
          </h2>
          <ModalButtonWrapper>
            <Button onClick={acceptSessionExpiration}>
              <LocalizedString id="ok" />
            </Button>
          </ModalButtonWrapper>
        </ExpirationModalInnerWrapper>
      </ExpirationModal>
    );
  }
  const queryClient = new QueryClient();

  return (
    <ErrorBoundary onError={setError} rumInitiated={rumInitiated}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={currentTheme}>
          <GlobalDefaultStyle />
          <VersionContextWrapper>
            <NotificationContext.Provider value={{ resetNotificationFetchTimeout }}>
              <ChatSupportProvider userInfo={props.userInfo} services={props.services}>
                {isLoggedIn && !props.readonly ? <GTM /> : null}
                <NewsModal news={!props.isOnboardingFinished ? [] : props.news} />
                <Router history={history}>
                  <ScrollToTop>
                    <AppBase id={outerContainerId}>
                      <ChangePasswordDialog
                        onSuccess={() => {
                          setPasswordModal(false);
                          displaySuccess(translateString("changePasswordDialog.success"));
                        }}
                        opened={openedPasswordModal}
                        onCancel={() => setPasswordModal(false)}
                      />
                      <ToastContainer
                        draggable
                        rtl={false}
                        newestOnTop
                        closeOnClick
                        pauseOnHover
                        hideProgressBar
                        autoClose={2000}
                        closeButton={false}
                        position="top-center"
                        toastClassName="wide-toast"
                      />

                      {!isLoggedIn && authRequired ? (
                        <H1>
                          <LocalizedString id="auth.redirectingToLogin" />
                        </H1>
                      ) : (
                        <OuterWrapper>
                          {authRequired &&
                            !userProfile.eulaOpened &&
                            !userProfile.userDetailsOpened &&
                            !props.isEnergyConsultant && (
                              <Joyride
                                continuous
                                showProgress
                                debug={false}
                                showSkipButton
                                callback={onboarding}
                                disableScrolling={false}
                                tooltipComponent={Tooltip}
                                steps={props.onboardingSteps}
                                run={props.isOnboardingRunning}
                                stepIndex={props.onboardingStep}
                                styles={{
                                  options: {
                                    textColor: colors.primary.black,
                                    arrowColor: colors.primary.white,
                                    primaryColor: colors.primary.blue,
                                    overlayColor: colors.transparent.black,
                                  },
                                  spotlight: {
                                    backgroundColor: colors.secondary.gray40,
                                  },
                                }}
                              />
                            )}

                          {authRequired ? (
                            <UserPrefs
                              isOpen={isMenuOpen}
                              toggleMenu={toggleMenu}
                              isDesigner={props.isDesigner}
                              onMenuStateChange={(s) => setMenuOpen(s.isOpen)}
                              showOnlyDesignerLinks={props.isEnergyConsultant}
                            />
                          ) : null}

                          <ReleaseNotesSidebar />
                          {authRequired && (!props.isEnergyConsultant || !props.isQuantipartsDistributor) && (
                            <NotificationSideBar
                              isOpen={isNotificationsOpen}
                              toggleMenu={toggleNotifications}
                              onMenuStateChange={onMenuStateChange}
                            />
                          )}

                          {authRequired && <ChatWindow />}

                          <InnerWrapper id={pageWrapId}>
                            <Switch>
                              {standAloneViews}
                              <Route
                                render={(routeProps) => (
                                  <div id={pageWrapId}>
                                    <Header
                                      {...routeProps}
                                      toggleMenu={toggleMenu}
                                      readonly={props.readonly}
                                      punchOut={props.punchOut}
                                      serviceAnnouncements={serviceAnnouncements}
                                      isLoggedIn={authRequired}
                                      isErrorState={error.hasError}
                                      dismissError={() =>
                                        setError({
                                          hasError: false,
                                        })
                                      }
                                      punchOutContact={props.punchOutContact}
                                      toggleNotifications={toggleNotifications}
                                      currentUserEmail={props.currentUserEmail}
                                      setSelectedInstallation={props.setSelectedInstallation}
                                      selectedInstallationName={props.selectedInstallationName}
                                    />
                                    <MainContainer>
                                      {error.hasError ? (
                                        <ErrorScreen
                                          {...routeProps}
                                          errorUUID={error.errorUUID}
                                          errorName={error.errorName}
                                          errorStack={error.errorStack}
                                          dismissError={() =>
                                            setError({
                                              hasError: false,
                                            })
                                          }
                                        />
                                      ) : (
                                        <React.Suspense
                                          fallback={
                                            <Container $margin={[3, 0]}>
                                              <LoadingSpinner />
                                            </Container>
                                          }
                                        >
                                          <Switch location={routeProps.location}>
                                            {views}
                                            <Route component={RedirectToIndex} />
                                          </Switch>
                                        </React.Suspense>
                                      )}
                                    </MainContainer>
                                    {props.readonly && <ReadOnlyBottomIndicator />}
                                    {props.punchOut && <PunchOutBottomIndicator />}
                                    {authRequired && !props.isEnergyConsultant && (
                                      <Contact isExternallyOpen={contactOpen} />
                                    )}
                                  </div>
                                )}
                              />
                            </Switch>
                          </InnerWrapper>

                          {authRequired ? (
                            <ExpirationModal isOpen={isSessionExpirationWarningOpen}>
                              <ExpirationModalInnerWrapper>
                                <h2>
                                  <LocalizedString id="auth.sessionWillExpire" />
                                </h2>

                                <ModalButtonWrapper>
                                  <Button onClick={refreshSession}>
                                    <LocalizedString id="auth.continueToUseOS" />
                                  </Button>
                                  <Button onClick={logout}>
                                    <LocalizedString id="auth.logout" />
                                  </Button>
                                </ModalButtonWrapper>
                              </ExpirationModalInnerWrapper>
                            </ExpirationModal>
                          ) : null}

                          {authRequired ? (
                            <ExpirationModal isOpen={isSessionExpirationOpen}>
                              <ExpirationModalInnerWrapper>
                                <h2>
                                  <LocalizedString id="auth.sessionExpired" />
                                </h2>

                                <ModalButtonWrapper>
                                  <Button onClick={acceptSessionExpiration}>
                                    <LocalizedString id="ok" />
                                  </Button>
                                </ModalButtonWrapper>
                              </ExpirationModalInnerWrapper>
                            </ExpirationModal>
                          ) : null}
                        </OuterWrapper>
                      )}

                      {isLoggedIn ? (
                        <>
                          <EULA
                            onCancel={logout}
                            isOpen={userProfile.eulaOpened}
                            onAgree={userProfile.acceptEULA}
                            isBlocking={userProfile.loading}
                            isConsultant={props.isEnergyConsultant}
                          />
                          <ConfirmInformationDialog
                            isOpen={userProfile.userDetailsOpened}
                            onConfirm={userProfile.confirmUserDetails}
                            onCancel={userProfile.dismissUserDetails}
                            initialInfo={userProfile.initialUserDetails}
                            isUpdateRequired={!userProfile.userDetailsUpdated}
                          />
                        </>
                      ) : null}
                    </AppBase>
                  </ScrollToTop>
                </Router>
              </ChatSupportProvider>
            </NotificationContext.Provider>
          </VersionContextWrapper>
        </ThemeProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};
