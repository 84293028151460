import {
  IAccount,
  IBasketDraft,
  IContact,
  IEquipment,
  ILineItem,
  IMultiplePartAvailability,
  IOrderContact,
  IPartModel,
  IPartOrderFrontendPayload,
  IPartQuoteFrontendPayload,
  IPartsAvailabilitiesQueryParams,
  IPartsAvailabilityQueryParams,
  IPendingRequest,
  IRecommendationsFrontendPayload,
  ISFBasketDraft,
  ISalesDocumentAttachment,
  ISalesDocumentPdf,
  IPartOrderSeparatePayload,
} from "online-services-types";
import { IUploadedFile, QueueStatus } from "src/components/AttachmentUploader/AttachmentUploaderComponent";
import { WartsilaLogoWhite, WartsilaLogoWithoutLink } from "src/images/WartsilaLogo";
import { getPathWithParams, getRoutes } from "../routes";

import { APIFetch } from "src/APIFetch";
import { History } from "history";
import React from "react";
import { displayError } from "./error";
import { doPendingRequest } from "src/util/pendingRequest";
import { getSelectedColorTheme } from "./localstorage";
import moment from "moment";
import { sparePartsDateFormat } from "src/util/formatters";
import styled from "styled-components";
import { translateString } from "./localization";

// Used when no spare part had been found by material number
export const DEFAULT_MATERIAL_ID = "TECH ID";
// SAP returns the string when part description is empty
export const IDENTIFICATION_REQUIRED = "Technical identification required";
export const COMPATIBLE_SERVICE_LEVELS = ["", "100", "200"];

export const deliveryPlants = {
  GLS: "GLS1",
  NL: "NL27",
};

export enum Branch {
  Order = "Order",
  Quotation = "Quotation",
}

export const deliveryModeByCode = {
  "01": translateString("spareParts.deliverymode.Courier"),
  "04": translateString("spareParts.deliverymode.AirFreight"),
  "07": translateString("spareParts.deliverymode.Truck"),
  "08": translateString("spareParts.deliverymode.SeaFreight"),
  "09": translateString("spareParts.deliverymode.Courier"),
  "11": translateString("spareParts.deliverymode.Truck"),
  "12": translateString("spareParts.deliverymode.Courier"),
  "14": translateString("spareParts.deliverymode.Courier"),
  "16": translateString("spareParts.deliverymode.AirFreight"),
  "17": translateString("spareParts.deliverymode.Courier"),
  "18": translateString("spareParts.deliverymode.Truck"),
  "20": translateString("spareParts.deliverymode.Truck"),
  "23": translateString("spareParts.deliverymode.Courier"),
  "25": translateString("spareParts.deliverymode.ToBeCollected"),
  "26": translateString("spareParts.deliverymode.Courier"),
  "31": translateString("spareParts.deliverymode.Courier"),
  "32": translateString("spareParts.deliverymode.Courier"),
  "33": translateString("spareParts.deliverymode.Courier"),
  "34": translateString("spareParts.deliverymode.Truck"),
  "35": translateString("spareParts.deliverymode.Courier"),
  "36": translateString("spareParts.deliverymode.Courier"),
  "37": translateString("spareParts.deliverymode.Courier"),
  "38": translateString("spareParts.deliverymode.Courier"),
  "39": translateString("spareParts.deliverymode.Courier"),
  "48": translateString("spareParts.deliverymode.Courier"),
  "50": translateString("spareParts.deliverymode.Courier"),
  "51": translateString("spareParts.deliverymode.Courier"),
};

export enum DeliveryModes {
  Truck = "Truck",
  Courier = "Courier",
  SeaFreight = "SeaFreight",
  AirFreight = "AirFreight",
  ToBeCollected = "ToBeCollected",
}

export const deliveryModeValues = {
  [DeliveryModes.Truck]: "07",
  [DeliveryModes.Courier]: "09",
  [DeliveryModes.SeaFreight]: "08",
  [DeliveryModes.AirFreight]: "04",
  [DeliveryModes.ToBeCollected]: "25",
};

export enum DeliveryTerms {
  DAP = "DAP",
  CIP = "CIP",
  CIF = "CIF",
  FCA = "FCA",
  DDP = "DDP",
  CPT = "CPT",
  CFR = "CFR",
}

export const deliveryTermsToModesMap = {
  [DeliveryTerms.DAP]: [
    DeliveryModes.Truck,
    DeliveryModes.Courier,
    DeliveryModes.SeaFreight,
    DeliveryModes.AirFreight,
    DeliveryModes.ToBeCollected,
  ],
  [DeliveryTerms.CIP]: [DeliveryModes.AirFreight],
  [DeliveryTerms.CIF]: [DeliveryModes.SeaFreight],
  [DeliveryTerms.FCA]: [DeliveryModes.ToBeCollected],
  [DeliveryTerms.DDP]: [DeliveryModes.Truck, DeliveryModes.Courier, DeliveryModes.SeaFreight, DeliveryModes.AirFreight],
  [DeliveryTerms.CPT]: [DeliveryModes.AirFreight],
  [DeliveryTerms.CFR]: [DeliveryModes.SeaFreight],
};

export interface IMultiplePartsItem {
  spn: string;
  qty: string;
  item?: IPartModel | ILineItem;
}

const WLogoWrapperWhite = styled.div`
  display: inline-block;
  img {
    margin-right: 10px;
    width: 5em;
  }
`;

const WLogoWrapperDark = styled.div`
  display: inline-block;
  img {
    margin-bottom: -15px;
    width: 5em;
  }
`;

export const isDeliveryTrackingVisible = (deliveryPlant: string = ""): boolean => {
  return deliveryPlant === deliveryPlants.GLS || deliveryPlant === deliveryPlants.NL;
};

const pdfDelay = 10000;

export const getMultipleParts = async (equipment: IEquipment, partsList: string[][]): Promise<IPartModel[]> => {
  return doPendingRequest<IPartModel[]>(() =>
    new APIFetch<{}, IPendingRequest>("spareparts/multiple-parts").post({ equipment, partsList })
  );
};

export const getMultiplePartsAvailability = async (
  equipmentId: string,
  params: IPartsAvailabilityQueryParams[],
  codeResolution?: boolean
): Promise<IMultiplePartAvailability[]> => {
  const parts: IPartsAvailabilityQueryParams[] = params.map((param) => ({
    ...param,
    date: moment().format(sparePartsDateFormat),
  }));
  return doPendingRequest<IMultiplePartAvailability[]>(() =>
    new APIFetch<IPartsAvailabilitiesQueryParams, IPendingRequest>("spareparts/multiple-availability").post({
      parts,
      equipmentId,
      codeResolution,
    })
  );
};

export const getAttachmentsForSalesDocument = async (salesDocumentId: string): Promise<ISalesDocumentAttachment[]> =>
  doPendingRequest<ISalesDocumentAttachment[]>(() =>
    new APIFetch<{}, IPendingRequest>("spareparts/attachments").get(salesDocumentId)
  );

export const getAttachmentDownloadUrl = async (salesDocumentId: string, filename: string): Promise<string> =>
  doPendingRequest<string>(() =>
    new APIFetch<{}, IPendingRequest>(`spareparts/attachment/${salesDocumentId}/${filename}`).get()
  );

export const getDeliveryAttachmentDownloadUrl = async (delivery: string, source: string): Promise<string> =>
  doPendingRequest<string>(() =>
    new APIFetch<{}, IPendingRequest>(`spareparts/deliveryattachment/${delivery}/${source}`).get()
  );

export const getClassificationAttachmentDownloadUrl = async (
  salesDocumentNumber: string,
  description: string
): Promise<string> =>
  doPendingRequest<string>(() =>
    new APIFetch<{}, IPendingRequest>(`spareparts/classificationattachment/${salesDocumentNumber}/${description}`).get()
  );

export const getPDFDetails = async (salesDocumentId: string): Promise<ISalesDocumentPdf> =>
  new APIFetch<ISalesDocumentPdf>(`spareparts/salesdocument/${salesDocumentId}/pdf`).get();

export const requestPdf = async (salesDocumentId: string): Promise<ISalesDocumentPdf> => {
  // We don't know if the PDF is ready, requestPdf() is making call to salesforce, and salesfroce is making call to
  // SAP to request the document, in the response we only get if the request was sent successfully or not.
  // Inserting the document to Salesforce from SAP is coming in another transaction, and it is unknown to us.
  // this will be refactored in the future.
  const request = new APIFetch(`spareparts/salesdocument/${salesDocumentId}/pdf`).post(salesDocumentId);
  return new Promise<ISalesDocumentPdf>((resolve, reject) => {
    request.then(() => {
      setTimeout(async () => {
        try {
          const response = await getPDFDetails(salesDocumentId);
          resolve(response);
        } catch (error) {
          reject(error);
        }
      }, pdfDelay);
    });
  });
};

export const patchDraft = async (draftBody: IBasketDraft): Promise<ISFBasketDraft | null> =>
  new APIFetch<IBasketDraft, ISFBasketDraft>("spareparts/basket-draft").patch(draftBody);

export const addItemsToBasket = async (
  partsByEquipment: { [equipmentId: string]: IMultiplePartsItem[] },
  equipments: IEquipment[],
  addItem: (item: IPartModel, quantity?: number, maxQuantity?: number, displayValue?: string) => void
): Promise<void> => {
  const draftByEquipmentResponse = Object.keys(partsByEquipment).map((key) => {
    const partsEquipment: IEquipment | undefined = equipments?.find((equipment) => equipment.id === key);
    return partsEquipment
      ? getMultipleParts(
          partsEquipment,
          partsByEquipment[key].map((part) => [part.spn, part.qty])
        )
      : undefined;
  });

  const equipmentWithParts = await Promise.all(draftByEquipmentResponse);

  Object.entries(partsByEquipment).forEach(([equipmentId, parts]) =>
    parts.forEach((relevantPart) => {
      const part = equipmentWithParts
        .flat()
        .find(
          (p) =>
            p &&
            p.equipmentId === equipmentId &&
            (p.quantity === relevantPart.item?.quantity || p.quantity === Number(relevantPart.qty)) &&
            (relevantPart.spn === p.materialId || relevantPart.spn === p.sparePartNumber || relevantPart.spn === p.id)
        );

      addItem(
        {
          ...relevantPart.item,
          ...part,
          sparePartNumber: part?.sparePartNumber || relevantPart?.item?.sparePartNumber || "",
          sfId: relevantPart.item?.sfId || part?.sfId || "",
          otherInformation: relevantPart.item?.otherInformation || "",
          materialText: part?.materialText || relevantPart.item?.materialText || "",
          serialNumber: part?.serialNumber || relevantPart.item?.serialNumber || "",
          vendorCode: part?.vendorCode || relevantPart.item?.vendorCode || "",
          markings: part?.markings || relevantPart.item?.markings || "",
          sizeAndDimensions: part?.sizeAndDimensions || relevantPart.item?.sizeAndDimensions || "",
          usageTarget: part?.usageTarget || relevantPart.item?.usageTarget || "",
        } as IPartModel,
        part?.quantity || relevantPart.item?.quantity || Number(relevantPart.qty)
      );
    })
  );
};

export const getContacts = (contacts: IContact[]): IOrderContact[] => {
  const orderContacts: IOrderContact[] = contacts.map(({ id, email }: IContact) => ({ Id: id || null, email }));
  return orderContacts ?? [];
};

export const fetchAttachmentsForItem = async (
  id: string
): Promise<Array<{ name: string; description?: string; id: string; status: QueueStatus }>> => {
  const attachments = await new APIFetch<IUploadedFile[]>("spareparts/record-attachments").get(id);
  return attachments.map((attachment) => ({ ...attachment, status: QueueStatus.Success }));
};

export const getSFPORequest = (
  selectedAccountId: string,
  selectedInstallationId: string | undefined,
  articleBasketId: string | null | undefined
) => ({
  accountId: selectedAccountId,
  installationId: selectedInstallationId ? selectedInstallationId : "",
  freeComment: "",
  contacts: [],
  deliveryMode: null,
  requestedETA: null,
  deliveryTerms: null,
  deliveryLocation: "",
  yourReference: "",
  lineItems: [],
  addresses: [],
  currencyCode: null,
  onlyCompleteDeliveryAllowed: true,
  articleBasketId,
});

export const getSFRFQRequest = (
  selectedAccountId: string,
  selectedInstallationId: string | undefined,
  basketDraftId: string | null,
  articleBasketId: string | null | undefined
) => ({
  accountId: selectedAccountId,
  basketId: basketDraftId,
  installationId: selectedInstallationId ? selectedInstallationId : "",
  freeComment: "",
  deliveryMode: null,
  requestedETA: null,
  deliveryTerms: null,
  deliveryLocation: "",
  contacts: [],
  yourReference: "",
  lineItems: [],
  currencyCode: null,
  onlyCompleteDeliveryAllowed: false,
  articleBasketId,
});

export const getDeliveryPlant = (equipments: IEquipment[], lines: ILineItem[]): string =>
  equipments
    .filter((eq) => lines.find((line) => line.equipmentId === eq.id))
    .reduce((prev, curr) => prev || curr.deliveryPlant, "");

export const getTranslationIdFromDeliveryStatus = (status?: string): string => {
  switch (status) {
    case "In process":
      return "spareParts.delivery.inProcess";
    case "Packed":
      return "spareParts.delivery.packed";
    case "Dispatched":
      return "spareParts.delivery.dispatched";
    case "Delivered":
      return "spareParts.delivery.delivered";
    case "Ready to be collected":
      return "spareParts.delivery.readyToBeCollected";
    case "Collected":
      return "spareParts.delivery.collected";
    default:
      return "request.track";
  }
};

/**
 * Converts line items to arrays of values in order to limit the payload size.
 */
const convertLineItemsFromObjectsToArrays = (payload: IPartOrderSeparatePayload): void => {
  if (payload && payload.orderLines.length > 0) {
    // Normalize line items so that all keys are present in all items and the keys are in identical order
    const normalizedOrderLines = normalizeLineItems(payload.orderLines);

    // Convert each line item to an array of its values
    payload.arrOrderLines = normalizedOrderLines.map((lineItem) => Object.values(lineItem));

    // Store the keys of the line items for reconstruction later
    payload.orderLineKeys = Object.keys(normalizedOrderLines[0]);

    // Set the original property empty in order to limit the payload size
    payload.orderLines = [];
  }
};

const normalizeLineItems = (items: ILineItem[]): ILineItem[] => {
  // Gather all keys
  const allKeys = new Set<string>();
  items.forEach((item) => {
    Object.keys(item).forEach((key) => allKeys.add(key));
  });

  // Sort the keys alphabetically
  const sortedKeys = Array.from(allKeys).sort();

  // Re-create the items with all keys present and sorted
  return items.map((item) => {
    const normalizedItem: any = {};
    sortedKeys.forEach((key) => {
      normalizedItem[key] = item[key] ?? null;
    });
    return normalizedItem as ILineItem;
  });
};

export const makePartsOrderRequest = async (
  partOrder: IPartOrderFrontendPayload,
  history: History,
  equipment: IEquipment[],
  draftId: string | null
): Promise<void> => {
  history.push(getPathWithParams(getRoutes().Loading, { action: "order-being-processed" }));
  const wartsilaDeliveryPlant: string = getDeliveryPlant(equipment, partOrder.wartsilaOrder.orderLines);
  const qpDeliveryPlant: string = getDeliveryPlant(equipment, partOrder.quantiPartsOrder.orderLines);

  convertLineItemsFromObjectsToArrays(partOrder.wartsilaOrder);
  convertLineItemsFromObjectsToArrays(partOrder.quantiPartsOrder);

  try {
    const result = await doPendingRequest<{} | undefined | null>(() =>
      new APIFetch<IPartOrderFrontendPayload, IPendingRequest>("spareparts/order").post({
        ...partOrder,
        wartsilaDeliveryPlant,
        qpDeliveryPlant,
      })
    );

    if (result) {
      history.push(getPathWithParams(getRoutes().CommerceOrderSuccess), {
        wartsilaReference: partOrder.wartsilaOrder.customerOrderID ?? "",
        quantiPartsReference: partOrder.quantiPartsOrder.customerOrderID ?? "",
        purchaseType: Branch.Order,
        draftId,
      });
    } else {
      displayError(translateString("spareParts.thereWasErrorWhenSendingOrder"));
    }
  } catch (err) {
    displayError(translateString("spareParts.thereWasErrorWhenSendingOrder"));
  }
};

// FETCH RECOMMENDATIONS ONLY FOR THESE REFTYPES
export const recommendationsRefTypes = [
  "22",
  "22/26",
  "28",
  "32",
  "34",
  "GE-SV",
  "W20",
  "W26",
  "W31",
  "W32",
  "W34",
  "W46",
  "W46F",
  "W50",
];

export const getRecommendations = async (
  account: IAccount,
  equipment: IEquipment[],
  items: ILineItem[]
): Promise<IPartModel[] | null> => {
  const basketItems: string[] = [];
  items.forEach((item) => {
    if (item.id !== DEFAULT_MATERIAL_ID && item.materialId !== DEFAULT_MATERIAL_ID) {
      basketItems.push(item.id || item.materialId);
    }
  });
  const deliveryPlant = getDeliveryPlant(equipment, items);

  return new APIFetch<IRecommendationsFrontendPayload, IPartModel[]>("spareparts/recommendations").post({
    equipmentId: equipment[0].id,
    customerId: account.partsSettings.customerId,
    salesOrganisation: account.partsSettings.salesOrganisation,
    distributionChannel: account.partsSettings.distributionChannel,
    division: account.partsSettings.division,
    deliveryPlant,
    prodRefTypeId: equipment[0].productReferenceType,
    basketItems,
  });
  /*
    const payload = {
      equipmentId: "300003270",
      customerId: "0000001035",
      salesOrganisation: "FI14",
      distributionChannel: "10",
      division: "40",
      deliveryPlant: "GLS1",
      prodRefTypeId: "32",
      basketItems: [
        "377003",
        "377002",
        "228105",
        "228176",
        "228174",
        "228180",
        "228164",
        "228163",
        "228162",
        "167150",
        "511113",
        "TI407",
        "TI406",
        "357007",
        "357012",
        "183050",
        "224050",
        "224071",
        "352075",
        "228173",
        "228170",
        "511101",
        "511102",
        "200011",
        "200025",
        "224089",
        "224090",
        "224017",
        "200138",
        "200139",
        "100021",
        "476001",
        "476012",
        "350064",
        "350065",
        "350024",
        "350025",
        "350403",
        "350022",
        "350023",
        "350045",
        "350043",
        "350056",
        "350021",
        "350060",
        "350026",
        "350175",
        "350061",
        "107028",
        "107179",
        "107182",
        "350042",
        "350432",
        "350025",
        "350408",
        "350130",
        "350050",
        "350134",
        "350421",
        "350167",
        "350133",
        "350132",
        "350417",
        "350472",
        "350474",
        "350467",
        "350473",
        "350474",
        "350025",
        "350417",
        "350429",
        "350184",
        "145025",
        "228171",
        "228172",
        "156009",
        "350039",
        "196266",
        "196267",
        "196275",
        "196264",
        "196414",
        "196274",
        "196251",
        "196290",
        "211019"
      ]
    };
    return new APIFetch<IRecommendationsFrontendPayload, IPartModel[]>("spareparts/recommendations").post(payload);

     */
};

export const makePartsQuoteRequest = async (
  quotationRequest: IPartQuoteFrontendPayload,
  history: History,
  equipment: IEquipment[],
  draftId: string | null
): Promise<void> => {
  history.push(getPathWithParams(getRoutes().Loading, { action: "quotation-being-processed" }));
  const wartsilaDeliveryPlant: string = getDeliveryPlant(equipment, quotationRequest.wartsilaQuote.orderLines);
  const qpDeliveryPlant: string = getDeliveryPlant(equipment, quotationRequest.quantiPartsQuote.orderLines);

  convertLineItemsFromObjectsToArrays(quotationRequest.wartsilaQuote);
  convertLineItemsFromObjectsToArrays(quotationRequest.quantiPartsQuote);

  try {
    const result = await doPendingRequest<{} | undefined | null>(() =>
      new APIFetch<IPartQuoteFrontendPayload, IPendingRequest>("spareparts/rfq").post({
        ...quotationRequest,
        wartsilaDeliveryPlant,
        qpDeliveryPlant,
      })
    );

    if (result) {
      history.push(getPathWithParams(getRoutes().CommerceQuoteSuccess), {
        wartsilaReference: quotationRequest.wartsilaQuote.customerOrderID ?? "",
        quantiPartsReference: quotationRequest.quantiPartsQuote.customerOrderID ?? "",
        purchaseType: Branch.Quotation,
        draftId,
      });
    } else {
      displayError(translateString("spareParts.thereWasErrorWhenSendingQuotation"));
    }
  } catch (err) {
    displayError(translateString("spareParts.thereWasErrorWhenSendingQuotation"));
  }
};

export const WartsilaLogoComponent = (): JSX.Element => {
  const theme = getSelectedColorTheme(); // TODO: get name from context
  return (
    <>
      {theme === "light" ? (
        <WLogoWrapperDark>
          <WartsilaLogoWithoutLink />
        </WLogoWrapperDark>
      ) : (
        <WLogoWrapperWhite>
          <WartsilaLogoWhite />
        </WLogoWrapperWhite>
      )}
    </>
  );
};

const cleanPartModel: Required<IPartModel> = {
  id: "",
  equipmentId: "",
  cylinder: "",
  page: "",
  position: "",
  materialId: "",
  sparePartNumber: "",
  materialText: "",
  sectionId: "",
  qtySection: 0,
  spnDescription: "",
  weightUnit: "",
  netWeight: 0,
  referenceEngine: "",
  unit: "",
  frontendKey: "",
  addedFromMatNum: false,
  sfId: "",
  productId: "",
  available: false,
  leadText: "",
  netPrice: 0,
  totNetPrice: 0,
  currency: "",
  quantity: 0,
  quantityInStock: 0,
  usageTarget: "",
  sizeAndDimensions: "",
  serialNumber: "",
  markings: "",
  otherInformation: "",
  attachmentIds: [],
  attachments: [],
  vendorCode: "",
  engineNumber: "",
  item_number: 0,
  unspscCode: "",
  isBomHeader: false,
  parentRow: "",
  unitOfMeasure: "",
  isAddedFromRecommendations: false,
  basedOn: [],
  dangerousGoodsIndicator: false,
  nonCancellableItem: false,
  currencyCode: "",
  serviceLevel: "",
};

// Removes fields that shouldn't be in an IPartModel object
export const sanitizePartModelProperties = (part: IPartModel): IPartModel => {
  const sanitizedModel = {};
  for (const key of Object.keys(cleanPartModel)) {
    sanitizedModel[key] = part[key];
  }
  return sanitizedModel as IPartModel;
};

const cleanLineItem: Required<ILineItem> = {
  ...cleanPartModel,
  lineNumber: 0,
  quantity: 0,
  quantityUnit: "",
  maxQuantity: 0,
  displayValue: "",
  otherInformation: "",
  attachmentIds: [],
  currencyCode: "",
  totalPrice: "",
  parentRow: "",
  isBomHeader: false,
  dispatchDate: "",
  deliveryDate: "",
  dispatchDateChanged: "",
  deliveryDateChanged: "",
  materialNumber: "",
  deliveredQuantity: 0,
  deliveryStatus: "",
  deliveries: [],
  weight: "",
  isCCECreated: false,
  cceDescription: "",
  itemNote: "",
  dangerousGoodsIndicator: false,
  nonCancellableItem: false,
};

// Removes fields that shouldn't be in an ILineItem object
export const sanitizeLineItemProperties = (part: ILineItem): ILineItem => {
  const sanitizedItem = {};
  for (const key of Object.keys(cleanLineItem)) {
    sanitizedItem[key] = part[key];
  }
  return sanitizedItem as ILineItem;
};
