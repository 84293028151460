// Wärtsilä Online translations exported from Salesforce. Please see "Translations" in Confluence for correct workflow.
import { ITranslations } from "../../localization";

// tslint:disable-next-line: variable-name
export const spareParts_es_MX: ITranslations = {
  es_MX: {
    "purchaseStatus.confirmed": "Confirmado",
    "purchaseStatus.draft": "Borrador",
    "purchaseStatus.expired": "Vencido",
    "purchaseStatus.inProcess": "En proceso",
    "purchaseStatus.ordered": "Ordenado",
    "purchaseStatus.quoted": "Cotizado",
    "spareParts": "Partes de repuesto",
    "spareParts.addLineItems": "Agregar artículos de línea",
    "spareParts.addManually": "Agregar manualmente",
    "spareParts.addMultipleLineItems": "Agregar múltiples artículos de línea",
    "spareParts.addressSaveFailure": "Error al guardar la dirección. Por favor intente de nuevo.",
    "spareParts.addressSaveSuccess": "Dirección guardada con éxito",
    "spareParts.addressWillBeInformedLater": "La dirección se informará más tarde",
    "spareParts.agreeToTermsAndConditionsIsRequired": "Debe aceptar los términos y condiciones",
    "spareParts.agreeWith": "Aceptar",
    "spareParts.allowPartiaDelivery": "Permitir entrega parcial",
    "spareParts.askForQuotation": "Solicitar cotización",
    "spareParts.askForQuote": "Solicitar cotización",
    "spareParts.availability": "Disponibilidad",
    "spareParts.availabilitySubjectDisclamer": "Disponibilidad sujeta a ventas previas.",
    "spareParts.basketCreatedBy": "Canasta creada por",
    "spareParts.basketPriceDisclaimer": "El proveedor cargará el monto de factura mínimo de 200€ en caso que la suma de su pedido total sea menor a una divisa equivalente a 200€.",
    "spareParts.basketWillBeDiscarded": "Hay cambios sin guardar en el carrito. Si sale de esta página, la canasta se vaciará. ¿Está seguro que desea continuar?",
    "spareParts.blockedCustomerMessage": "Desafortunadamente no puede continuar. Por favor póngase en contacto con Wärtsilä.",
    "spareParts.browseSpareParts": "Explorar partes de repuesto",
    "spareParts.chooseProduct": "Elegir producto...",
    "spareParts.contactInformation": "Información del contacto",
    "spareParts.copy": "Copiar",
    "spareParts.copyItemsPrompt": "Copie los números de parte en el formato siguiente",
    "spareParts.createdBy": "Creado por",
    "spareParts.createfirstOrder": "Crear su primer pedido",
    "spareParts.creationDate": "Fecha de creación",
    "spareParts.currency": "Divisa",
    "spareParts.deleteBasketDraft": "Eliminar borrador",
    "spareParts.deliveryAddress": "Dirección de entrega",
    "spareParts.deliveryAddressIsFinalDestination": "La dirección de entrega es el destino final",
    "spareParts.deliveryInformation": "Información de entrega",
    "spareParts.deliveryLocation": "Ubicación de entrega",
    "spareParts.deliveryLocationIsRequired": "Se requiere ubicación de entrega",
    "spareParts.deliveryMode": "Modo de entrega",
    "spareParts.deliveryModeIsRequired": "Se requiere modo de entrega",
    "spareParts.deliveryTerms": "Términos de entrega",
    "spareParts.deliveryTermsIsRequired": "Se requieren términos de entrega",
    "spareParts.deliverymode.AirFreight": "Flete aéreo",
    "spareParts.deliverymode.Courier": "Mensajero",
    "spareParts.deliverymode.SeaFreight": "Flete marítimo",
    "spareParts.deliverymode.ToBeCollected": "A recoger",
    "spareParts.deliverymode.Truck": "Camión",
    "spareParts.description": "Descripción",
    "spareParts.discard": "Descartar",
    "spareParts.dispatchingInformation": "Enviando información",
    "spareParts.draft": "Borrador",
    "spareParts.draftDeleteFailure": "Error al eliminar el borrador",
    "spareParts.draftDeleteSuccess": "Se borró con éxito el borrador de canasta",
    "spareParts.draftName": "Nombre de borrador",
    "spareParts.draftNameIsRequired": "Se requiere nombre de borrador",
    "spareParts.draftSaveFailure": "Error al guardar el borrador de canasta.",
    "spareParts.draftSaveSuccess": "Se guardó con éxito el borrador de canasta",
    "spareParts.draftUpdateSuccess": "Se actualizó con éxito el borrador de canasta",
    "spareParts.editInformation": "Editar información",
    "spareParts.emailAddresses": "Direcciones de correo electrónico",
    "spareParts.emptyBasket": "Su carrito de compras está vacío",
    "spareParts.errorFetchingPDF": "Error al recuperar los detalles de pdf",
    "spareParts.estimatedWeight": "Peso estimado de partes",
    "spareParts.etaDate": "Fecha de Tiempo estimado de llegada (ETA) solicitada",
    "spareParts.etaDateIsRequired": "Se requiere Fecha de Tiempo estimado de llegada (ETA)",
    "spareParts.export": "Exportar",
    "spareParts.exportAsCSV": "Exportar como CSV",
    "spareParts.exportAsPDF": "Exportar como PDF",
    "spareParts.failedFetchingManualParts": "Error de red",
    "spareParts.filterPurchases": "Filtrar compras",
    "spareParts.finalDestination": "Destino final",
    "spareParts.finalDestinationIsDifferent": "La dirección del destino final es diferente",
    "spareParts.freeComment": "Información Adicional",
    "spareParts.freeCommentPlaceholder": "Por ejemplo, instrucciones especiales para empaque, solicitudes para documentación, etc.",
    "spareParts.freightCosts": "Costos de flete",
    "spareParts.freightCostsOnlyAddedAtInvoicing": "Los costos de flete sólo se agregan en la facturación",
    "spareParts.generalInformation": "Información general",
    "spareParts.goodsTotal": "Total de productos",
    "spareParts.howToDispatchOrder": "¿Cómo desea que se envíe el pedido?",
    "spareParts.identificationRequired": "Se requiere identificación de técnico",
    "spareParts.inAccordanceWithITCode": "De acuerdo con el artículo 1341 y 1342 del Código Civil Italiano.",
    "spareParts.inStock": "EN EXISTENCIAS",
    "spareParts.isDeliveryAddressFinalDestinationAddress": "¿La dirección de entrega es el destino final para el pedido?",
    "spareParts.lastItemDialogText": "¿Está seguro que desea eliminar este artículo de su carrito?",
    "spareParts.lastItemDialogTitle": "Eliminando artículo",
    "spareParts.lastModified": "Última modificación",
    "spareParts.leadTime": "Plazo de elaboración",
    "spareParts.lineItems": "Artículos de línea",
    "spareParts.loadBasketDraft": "Cargar borrador",
    "spareParts.loadMore": "Cargar más",
    "spareParts.location": "Ubicación",
    "spareParts.makeAnotherOrder": "Realizar otro pedido",
    "spareParts.makeAnotherQuote": "Realizar otra cotización",
    "spareParts.markings.optional": "Marcas (opcional)",
    "spareParts.minimumPossibleBusinessDateLimit": "La fecha más próxima posible puede ser 3 días hábiles desde ahora",
    "spareParts.nextStep": "Siguiente paso",
    "spareParts.noCatalogueFound": "Lo siento - el catálogo de producto ya no está disponible para",
    "spareParts.noDescription": "No hay descripción disponible",
    "spareParts.noFilteredPartFound": "Lo siento - no se encontró parte para",
    "spareParts.noItemsAvailable": "No hay artículos disponibles",
    "spareParts.noPDFinSAP": "PDF no disponible, póngase en contacto con su Equipo de partes de repuesto.",
    "spareParts.noPicturesFound": "No se encontraron imágenes",
    "spareParts.noQuotationsOrOrders": "No ha creado ninguna cotización o pedido.",
    "spareParts.noSalesDocumentsFound": "No se encontraron documentos de ventas",
    "spareParts.notOfficialQuotationDisclaimer": "No es una cotización oficial. Los precios están sujetos al precio de lista global de acuerdo a la lista de precios en la fecha de compra. Disponibilidad sujeta a ventas previas.",
    "spareParts.on": "On (encendido)",
    "spareParts.onlyAllowCompleteOrder": "Sólo se permite pedido completo",
    "spareParts.onlyNumberOrNameRequired": "Sólo se requiere el número de parte de repuesto o el nombre de la parte en esta tabla. La siguiente pantalla contendrá opciones para agregar más detalles sobre la parte.",
    "spareParts.order": "Pedido",
    "spareParts.orderConfirmed": "Su pedido ya está confirmado",
    "spareParts.orderDetails": "Detalles de pedido",
    "spareParts.orderFullDispatch": "Su pedido se envió completamente",
    "spareParts.orderNew": "Se creó nuevo pedido",
    "spareParts.orderPartialDispatch": "Su pedido se envió parcialmente",
    "spareParts.orderReceived": "Pedido recibido",
    "spareParts.partDescription": "Descripción de parte",
    "spareParts.partDescriptionIsRequired": "Se requiere la descripción de parte",
    "spareParts.partNameOrNumber": "Nombre/número de parte",
    "spareParts.partNotFound": "No se encontró la parte. Especifique la información adicional.",
    "spareParts.partNumber": "Número de parte",
    "spareParts.partOrder.pleaseNoteMayTakeUpMinutes": "Puede tomar unos cuantos minutos para que su pedido aparezca en Mis partes de repuesto.",
    "spareParts.partOrder.useReferenceNumber": "Puede usar su referencia para encontrar su pedido a partir de Mis partes de repuesto.",
    "spareParts.partOrder.weHaveReceived": "Hemos recibido su pedido.",
    "spareParts.partOrder.weWillGetBackToYouOrder": "Le responderemos tan pronto como se procese su pedido.",
    "spareParts.partWeight": "Peso",
    "spareParts.partWeightUnit": "Unidad de peso",
    "spareParts.pictures": "Imágenes",
    "spareParts.price": "Precio",
    "spareParts.priceNoVatDisclaimer": "Los precios no incluyen IVA, y se agregará en base a la legislación de IVA válida aplicable al momento de la facturación.",
    "spareParts.product": "Producto",
    "spareParts.productId": "Identificación de producto",
    "spareParts.productIsNotSelected": "El producto no está seleccionado",
    "spareParts.purchases": "Compras",
    "spareParts.qtySection": "Cantidad en esta sección",
    "spareParts.quantity": "Cantidad",
    "spareParts.quantityUnit": "Unidad de cantidad",
    "spareParts.quotation": "Cotización",
    "spareParts.quotationCannotBeCopied": "No se puede copiar cotización con partes desconocidas",
    "spareParts.quotationCreated": "Se creó nueva cotización",
    "spareParts.quotationQuoted": "Se confirmaron los precios para su cotización",
    "spareParts.quotationReceived": "Cotización recibida",
    "spareParts.quotationRequest.pleaseNoteMayTakeUpMinutes": "Puede tomar unos cuantos minutos para que su cotización aparezca en Mis partes de repuesto.",
    "spareParts.quotationRequest.useReferenceNumber": "Puede usar su referencia para encontrar su cotización a partir de Mis partes de repuesto.",
    "spareParts.quotationRequest.weHaveReceived": "Hemos recibido su cotización.",
    "spareParts.quotationRequest.weWillGetBackToYouQuotation": "Le responderemos tan pronto como se procese su cotización.",
    "spareParts.quoteDetails": "Detalles de cotización",
    "spareParts.referenceType": "Tipo de referencia",
    "spareParts.requestPDF": "Solicitar PDF",
    "spareParts.rows": "Filas",
    "spareParts.saveAddress": "Guardar dirección",
    "spareParts.saveInformation": "Guardar información",
    "spareParts.saveProgressForLater": "¿Desea guardar su progreso para después?",
    "spareParts.selectProduct": "Seleccionar producto",
    "spareParts.serialNumber.optional": "Número de serie (opcional)",
    "spareParts.serviceGridButtonTitle": "Comercio",
    "spareParts.shoppingCart": "Carrito de compras",
    "spareParts.shoppingCartEmpty": "Carrito de compras vacío",
    "spareParts.sizeAndDimensions.optional": "Tamaño y dimensiones (opcional)",
    "spareParts.sorting.purchaseTypeAZ": "Tipo de compra (A-Z)",
    "spareParts.specificClauses": "Cláusulas específicas",
    "spareParts.subtotal": "Subtotal",
    "spareParts.summary": "Resumen",
    "spareParts.termsAndConditions": "Términos y condiciones",
    "spareParts.termsConditionsDisclaimerPDF": "Todas las ventas de partes están sujetas a los Términos y condiciones generales – Partes (2025). En el caso que la cantidad a ser facturada por el Proveedor sea menor a una cantidad de divisa equivalente a 200 EUR, el Proveedor cargará un extra y el monto mínimo que se deberá facturar deberá ser 200 EUR o monto equivalente de la divisa aplicable agregada con la tarifa para los costos de transporte, si hubiese.",
    "spareParts.termsDisclaimer": "Si firmó un Acuerdo de suministro de partes de repuesto con Wärtsilä que esté en vigor cuando se realizó el pedido, sólo aplicarán los términos y condiciones de tal acuerdo.",
    "spareParts.thankYou": "Gracias",
    "spareParts.thereWasErrorWhenSendingOrder": "Hubo un error al enviar su pedido.",
    "spareParts.thereWasErrorWhenSendingQuotation": "Hubo un error al enviar su cotización.",
    "spareParts.total": "Total",
    "spareParts.totalSum": "Suma total",
    "spareParts.typeInEmails": "Escriba las direcciones de correo electrónico",
    "spareParts.typePartNameOrNumber": "Escriba el nombre o número de parte",
    "spareParts.unsavedChanges": "Cambios no guardados",
    "spareParts.usageTarget.optional": "Uso/Sistema/Unidad (opcional)",
    "spareParts.vatDisclaimer": "Los precios no incluyen IVA, y se agregará en base a la legislación de IVA válida aplicable al momento de la facturación.",
    "spareParts.vendorCode.optional": "Código de parte de vendedor (opcional)",
    "spareParts.wartsilaReference": "Referencia Wärtsilä",
    "spareParts.youCanAddPartsUsingFollowingAction": "También puede agregar partes de repuesto usando la siguiente acción",
    "spareParts.yourReference": "Su referencia",
    "spareParts.yourReferenceIsRequired": "Se requiere su referencia",
    "spareparts.errorFetchingDetails": "Error al recuperar los detalles de pedido",
  },
};
