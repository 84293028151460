import { TextArea } from "@wartsila/ui-kit";
import { ButtonLink } from "src/design-system/Button";
import { FlexContainer } from "src/design-system/Container";
import colors from "src/design-system/Tokens/colors";
import { baseTextStyles, H2Styles, smallTextStyles } from "src/design-system/Tokens/typography";
import styled from "styled-components";

export const MenuToggle = styled(ButtonLink)`
  ${H2Styles};
  line-height: 0;
`;

interface IIndicatorProps {
  showIndicator: boolean;
}

export const IndicatorBubble = styled.span`
  position: relative;

  ${(props: IIndicatorProps) =>
    props.showIndicator &&
    `
    ::after {
      ${smallTextStyles};
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      top: -12px;
      right: 0;
      border-radius: 50%;
      background-color: ${colors.notification.ok};
      line-height: 1.5;
    }
  `}
`;

export const StyledContainer = styled(FlexContainer)`
  ${baseTextStyles};
`;

export const DialogContentWrapper = styled.div`
  height: calc(100% - 80px);
  padding: 0 1px;
  box-shadow: inset 0 0 10px -10px ${colors.transparent.black};
`;

export const ChatContainer = styled(FlexContainer).attrs({ $flex: "1", $column: true })`
  height: 100%;
  min-height: 250px;
`;

export const ChatMessagesContainer = styled(FlexContainer).attrs({ $flex: "1" })`
  overflow: auto;
  flex-direction: column-reverse;
`;

export const ChatBoxWrapper = styled.div`
  border: 1px solid ${colors.primary.black};
`;

export const ChatTextArea = styled(TextArea)`
  max-width: 100%;
  height: 100px;
  border: none;
  resize: none;
  padding-bottom: 0;

  &:focus {
    box-shadow: none;
    border: none;
  }
`;

export const ChatButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 34px;
`;

export const IconButton = styled.button.attrs({ type: "button" })`
  border: none;
  padding: 5px;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${colors.primary.blue};
  ${({ smallText }: { smallText?: boolean }) => (smallText ? smallTextStyles : "")}
`;

export const ChatInfoMessage = styled.div`
  ${smallTextStyles};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: ${colors.secondary.gray40};
  font-weight: 400;
  word-break: break-word;
`;
