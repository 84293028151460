import * as React from "react";

import { ActionsWrapper, ContentWrapper, DialogHeader } from "../Dialog/Dialog";
import { ArrowLeftIcon, IconSize } from "src/icons";
import { Button, ButtonStyle } from "src/design-system/Button";
import { Modal, ModalSize } from "src/components/Dialog/Modal";
import { getRoutes, redirectBrowser, redirectBrowserToURL } from "src/routes";

import { IIconBase } from "src/icons/IconBase";
import { INewsItem } from "online-services-types";
import { LocalizedString } from "src/components/Localization";
import { P } from "src/design-system/Tokens/typography";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import colors from "src/design-system/Tokens/colors";
import { filterModalNewsItems } from "src/views/NewsView/news";
import { get } from "lodash";
import { getBase64Src } from "src/util/getBase64Src";
import { logNewsReadMore } from "src/util/viewlog";
import { setNewsSeenInModal } from "src/util/localstorage";
import styled from "styled-components";

const ModalWrapper = styled.div`
  display: block;
  height: 100%;

  & h1 {
    text-transform: none;
  }
`;

const NewsWrapper = styled.div`
  display: flex;
`;

const ItemWrapper = styled.div`
  flex: 1;
  min-width: 400px;
  display: flex;
  gap: 1rem;
  @media (max-width: ${breakpoints.mobileLarge}px) {
    flex-direction: column;
    min-width: 200px;
  }
`;

const NextPrevWrapper = styled.div`
  width: 48px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > * {
    margin: auto;
    width: 48px;
    height: 48px;
  }
`;

const NextTextContent = styled(P)`
  margin-top: 0;
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;

  & > *:first-child {
    margin-top: 0;
  }
`;

const ImageWrapper = styled.div`
  width: 200px;
  height: 113px;

  margin: 0 auto;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  max-width: 450px;

  @media (max-width: ${breakpoints.mobileLarge}px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 200px;
    text-align: left;
  }
`;

const ArrowRightIcon = (props: IIconBase) => <ArrowLeftIcon transform="scale(-1,1)" {...props} />;

export const NewsModalComponent = ({ news = [] }: { news: INewsItem[] }) => {
  const [opened, setOpened] = React.useState(false);
  const [splashNews, setSplashNews] = React.useState(news);
  const [selectedItemIndex, selectItemIndex] = React.useState(0);

  React.useEffect(() => {
    const filteredNews = filterModalNewsItems(news, undefined);
    setSplashNews(filteredNews);
    if (filteredNews.length > 0) {
      setOpened(true);
    }
  }, [news]);

  const onClose = () => {
    setOpened(false);

    // Consider all the news read - do not show on the next render
    setNewsSeenInModal(news.map((item) => item.id));
  };

  const onMovePage = (direction: "next" | "prev") => {
    selectItemIndex((direction === "next" ? selectedItemIndex + 1 : selectedItemIndex - 1) % splashNews.length);
  };

  const onReadMore = () => {
    logNewsReadMore(get(splashNews, selectedItemIndex).id);
    redirectBrowserToURL(get(splashNews, selectedItemIndex).linkHref, true);
    onClose();
  };

  const onQuotationRequest = () => {
    redirectBrowser(getRoutes().RequestQuotation, {
      newsItemId: get(splashNews, selectedItemIndex).id,
    });
    onClose();
  };

  const item = get(splashNews, selectedItemIndex);
  const isOpen = opened && Boolean(item);
  const title = item ? item.title : "";

  return (
    <Modal isOpen={isOpen} size={ModalSize.Large}>
      <ModalWrapper>
        <DialogHeader title={title} onCancel={onClose} />

        <ContentWrapper noOverflow>
          <NewsWrapper>
            <NextPrevWrapper onClick={() => onMovePage("prev")}>
              <ArrowLeftIcon color={colors.primary.orange} size={IconSize.Large} />
            </NextPrevWrapper>
            <ItemWrapper>
              {item?.imageData ? (
                <ImageWrapper>
                  <img alt="" src={getBase64Src(item.imageData)} />
                </ImageWrapper>
              ) : null}
              {item?.fullText ? (
                <TextWrapper>
                  <NextTextContent dangerouslySetInnerHTML={{ __html: item.fullText }} />
                </TextWrapper>
              ) : null}
            </ItemWrapper>
            <NextPrevWrapper onClick={() => onMovePage("next")}>
              <ArrowRightIcon color={colors.primary.orange} size={IconSize.Large} />
            </NextPrevWrapper>
          </NewsWrapper>
        </ContentWrapper>

        <ActionsWrapper>
          {item?.requestQuotationEnabled ? (
            <Button buttonStyle={ButtonStyle.Primary} onClick={onQuotationRequest}>
              <LocalizedString id="news.requestQuotation" />
            </Button>
          ) : null}
          <Button buttonStyle={ButtonStyle.Primary} onClick={onReadMore}>
            <LocalizedString id="news.readMore" />
          </Button>
        </ActionsWrapper>
      </ModalWrapper>
    </Modal>
  );
};
