import { Tooltip, TooltipArrow } from "@wartsila/ui-kit";
import { Parser, ProcessNodeDefinitions } from "html-to-react";
import { sortBy } from "lodash";
import {
  ICustomerSupportTicket,
  IDelivery,
  INotification,
  IOperationalSupportRequest,
  IServiceLimitations,
  ISparePartClaim,
  ITechRequest,
  IWarrantyClaim,
} from "online-services-types";
import * as React from "react";

import { APIFetch } from "src/APIFetch";
import { AttachmentUploader } from "src/components/AttachmentUploader";
import { LabelIconWrapper } from "src/components/CommerceList/columns";
import { ResponsiveDeliveryTree } from "src/components/CommerceList/ResponsiveDeliveryTree";
import { LocalizedString } from "src/components/Localization";
import { PortalTooltip } from "src/components/Portal";
import { RequestComments } from "src/components/RequestComments";
import { IAppClaimComment } from "src/components/RequestComments/RequestCommentsComponent";
import { IResponsiveTableColumn } from "src/components/ResponsiveTable/interfaces";
import { getRatingNames } from "src/components/SupportList/Approval/ratingNames";
import { CloseClaimButton } from "src/components/SupportList/CloseClaimButton";
import { DraftButtons } from "src/components/SupportList/DraftButtons";
import { InterestedPeople } from "src/components/SupportList/InterestedPeople";
import { ReopenComponent } from "src/components/SupportList/RatingButtons/ReopenComponent";
import { RowNotification } from "src/components/SupportList/RowNotification";
import {
  ICombinedRequestWithNotificationsViewModel,
  IWarrantyClaimListComponentState,
  IWarrantyClaimListProps,
} from "src/components/SupportList/SupportListComponent";
import { Button } from "src/design-system/Button";

import { Container, FlexContainer } from "src/design-system/Container";
import { Link } from "src/design-system/Link";
import colors from "src/design-system/Tokens/colors";
import { Disclaimer } from "src/design-system/Tokens/typography";
import { DisclaimerTooltip } from "src/design-system/Tooltip/Tooltip";
import { QuantiPartsIcon } from "src/icons";
import { filterSolutionPlanNotifications, INotificationContext } from "src/models/notifications";
import {
  ICombinedRequestViewModel,
  ICustomerSupportTicketViewModel,
  ITechRequestViewModel,
  IWarrantyClaimViewModel,
  RequestType,
} from "src/models/warrantyClaim";
import { getPathWithParams, getRoutes, IRoute, IRouteParams } from "src/routes";
import { OpenChatButton } from "src/services/atrs/atrs.components";
import { formatBoolean, formatDate, formatDateTime } from "src/util/formatters";
import { translateString } from "src/util/localization";
import { getRequestStatusName, RequestStatus } from "src/util/requestStatus";
import { Branch } from "src/views/CreateWarrantyClaimView/Branches";
import styled from "styled-components";
import { ResponsiveTable } from "../ResponsiveTable";
import { AttachmentList } from "./AttachmentList";

type IReopenableRequestViewModel = ICustomerSupportTicketViewModel | ITechRequestViewModel | IOperationalSupportRequest;

const displayReopenButton = (item: ICombinedRequestWithNotificationsViewModel): boolean => {
  // Rating buttons include a reopen button, so do not display
  // the separate reopen button in case rating buttons are visible.
  if ((item as IReopenableRequestViewModel).reopenAllowed && !(item as IReopenableRequestViewModel).ratingAllowed) {
    return true;
  }
  return false;
};

export const reopenButton = (
  props: IWarrantyClaimListProps
): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "reopenButton",
  plannedWidth: 300,
  priority: 10,
  valueRenderer: ({ row }) => {
    return (
      <ReopenComponent
        item={row as ICombinedRequestViewModel}
        reopenRequest={props.reopenRequest}
        standalone={true}
        displayReopenButton={displayReopenButton(row)}
      />
    );
  },
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) =>
    row &&
    (row as IReopenableRequestViewModel).reopenAllowed !== undefined &&
    (row as IReopenableRequestViewModel).ratingAllowed !== undefined &&
    displayReopenButton(row) &&
    (isEnabledForTechRequest(row) || isEnabledForCSTicket(row) || isEnabledForOperationalSupport(row)),
});

export const openClaim = (
  props: IWarrantyClaimListProps
): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "openClaim",
  plannedWidth: 300,
  priority: 10,
  valueRenderer: ({ row }) => <DraftButtons claim={row} onDelete={props.deleteDraft} />,
  isEnabled: (row: ICombinedRequestViewModel) => row.status === RequestStatus.Draft,
});

const InfoTooltip = styled(Tooltip)`
  transform: translate(-55%, -180%);
  margin-bottom: 1.2rem;
  position: absolute;
  z-index: 1000;
`;

export const subject = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  key: "subject",
  label: translateString("request.subject"),
  plannedWidth: 250,
  priority: 10,
});

export const product = (
  props: IWarrantyClaimListProps
): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  key: "equipmentId",
  label: translateString("equipment"),
  plannedWidth: 175,
  priority: 3,
  valueRenderer: ({ row }) => {
    if (
      row &&
      row.equipment &&
      row.equipment.serialNumber &&
      row.equipment.isQuantiparts &&
      !props.isQuantipartsDistributor
    ) {
      return (
        <FlexContainer>
          {row.equipment.serialNumber}
          <LabelIconWrapper>
            <QuantiPartsIcon />
          </LabelIconWrapper>
        </FlexContainer>
      );
    }
    if (row && row.equipment && row.equipment.serialNumber) {
      return row.equipment.serialNumber;
    }
    return "";
  },
});

export const vessel = (
  props: IWarrantyClaimListProps
): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  key: "installationId",
  label: translateString("installation"),
  plannedWidth: 150,
  priority: 1,
  valueRenderer: ({ row }) => {
    if (row && row.installation && row.installation.name) {
      return row.installation.name;
    }
    return "";
  },
  isEnabled: () => props.showInstallationColumn,
});

export const customerClaimReference = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  key: "customerClaimNumber",
  label: "",
  plannedWidth: 200,
  priority: 2,
  labelRenderer: ({ row }) =>
    row && row.customerClaimNumber ? `${translateString("request.customerClaimReference")}` : "",
  valueRenderer: ({ row }) => (row && row.customerClaimNumber ? row.customerClaimNumber : ""),
});

export const detailedDescription = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "detailedProblemDescription",
  label: translateString("request.detailedDescription"),
  plannedWidth: 200,
  priority: 4,
  hideEmptyValues: true,
});

export const status = (
  onConfirmClose?: (event: React.MouseEvent, claim: IWarrantyClaim) => void
): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  key: "displayStatus",
  label: translateString("status"),
  plannedWidth: 110,
  priority: 5,
  valueRenderer: ({ row }) => {
    if (row.requestType === RequestType.WarrantyClaim && row.displayStatus === RequestStatus.Closed) {
      return (
        <>
          {getRequestStatusName(row.displayStatus as RequestStatus) + " "}
          {(row as IWarrantyClaim).pendingCustomerConfirmation === "Pending Customer Confirmation" &&
          !(row as IWarrantyClaim).confirmedByCustomer ? (
            <DisclaimerTooltip
              title={translateString("request.pendingCustomerConfirmation")}
              arrow={TooltipArrow.Top}
              left={0}
              xOffset={-100}
              listData={[
                {
                  label: "",
                  text: (
                    <Button
                      onClick={(event) => (onConfirmClose ? onConfirmClose(event, row as IWarrantyClaim) : undefined)}
                    >
                      {translateString("request.confirmClosedClaim")}
                    </Button>
                  ),
                },
              ]}
            />
          ) : null}
        </>
      );
    }
    return getRequestStatusName(row.displayStatus as RequestStatus);
  },
});

export const requestId = (
  props: IWarrantyClaimListProps
): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  key: "displayId",
  label: translateString("request.requestId"),
  plannedWidth: 125,
  priority: props.includeWarrantyId ? 10 : 4,
  hideEmptyValues: true,
});

export const description = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "description",
  label: translateString("request.description"),
  plannedWidth: 200,
  priority: 4,
  valueRenderer: ({ value }) => {
    return <PreWrapText>{value}</PreWrapText>;
  },
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) =>
    isEnabledForTechRequest(row) ||
    isEnabledForCSTicket(row) ||
    isEnabledForWarrantyClaim(row) ||
    isEnabledForOperationalSupport(row),
});

export const productref = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "productReferenceType",
  label: translateString("request.productReferenceType"),
  plannedWidth: 200,
  priority: 1,
  hideEmptyValues: true,
  valueRenderer: ({ row }) => {
    if (row && row.equipment && row.equipment.productReferenceType) {
      return row.equipment.productReferenceType;
    }
    return "";
  },
});

const tooltipLink = (textId: string, link: ITechRequest | IWarrantyClaim, linkText: string) => {
  const route: IRoute = getRoutes().Requests;
  return (
    <div style={{ border: "1px solid black", padding: "1em", margin: "1em 1em 1em 0" }}>
      <LocalizedString
        id={textId}
        values={{
          link: (
            <PortalTooltip
              component={
                <InfoTooltip arrow={TooltipArrow.Bottom}>
                  <div>
                    <LocalizedString
                      id="request.linked.status"
                      values={{
                        status: <strong>{getRequestStatusName(link.status as RequestStatus)}</strong>,
                      }}
                    />
                  </div>
                  <div>
                    <LocalizedString
                      id="request.linked.lastModified"
                      values={{ lastModified: <strong>{formatDate(link.lastModifiedDate)}</strong> }}
                    />
                  </div>
                  {link.lastCommentDate && (
                    <div>
                      <LocalizedString
                        id="request.linked.lastComment"
                        values={{
                          lastCommentDate: <strong>{formatDate(link.lastCommentDate)}</strong>,
                          lastCommentBy: <strong>{link.lastCommentBy}</strong>,
                        }}
                      />
                    </div>
                  )}
                </InfoTooltip>
              }
            >
              <Link to={getPathWithParams(route, { requestId: link.id })}>{linkText}</Link>
            </PortalTooltip>
          ),
        }}
      />
    </div>
  );
};

export const linkedRequests = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "linkedRequests",
  label: "",
  plannedWidth: 1000,
  priority: 10,
  isEnabled: (row) => Boolean((row as ITechRequest).linkedWarranty) || Boolean((row as IWarrantyClaim).linkedATRS),
  renderer: ({ row }) => {
    if (row.requestType === RequestType.TechRequest && Boolean((row as ITechRequest).linkedWarranty)) {
      return tooltipLink(
        "request.linked.techRequestHasWarranty",
        (row as ITechRequest).linkedWarranty!,
        (row as ITechRequest).linkedWarranty!.warrantyId
      );
    } else if (row.requestType === RequestType.WarrantyClaim && Boolean((row as IWarrantyClaim).linkedATRS)) {
      return tooltipLink(
        "request.linked.warrantyHasTechRequest",
        (row as IWarrantyClaim).linkedATRS!,
        (row as IWarrantyClaim).linkedATRS!.techRequestId
      );
    }
    return null;
  },
});

export const openChat = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "isAtrs",
  label: "",
  plannedWidth: 200,
  priority: 10,
  hideEmptyValues: true,
  isEnabled: (row) => isEnabledForTechRequest(row) && Boolean((row as ITechRequest).isAtrs),
  renderer: ({ row }) => {
    return <OpenChatButton caseId={row.id} />;
  },
});

export const createdDate = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "creationDate",
  label: translateString("request.creationDate"),
  plannedWidth: 200,
  priority: 1,
  valueRenderer: ({ row }) => {
    return formatDate(new Date(row.creationDate));
  },
});

export const submittedDate = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "submittedDate",
  label: translateString("request.submittedDate"),
  plannedWidth: 200,
  priority: 1,
  isEnabled: (row) => Boolean(row.submittedDate),
  valueRenderer: ({ row }) => {
    return formatDateTime(new Date(row.submittedDate));
  },
});

export const lastModifiedDate = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  key: "lastModifiedDate",
  label: translateString("request.lastModified"),
  plannedWidth: 125,
  priority: 1,
  hideEmptyValues: true,
  valueRenderer: ({ row }) => {
    return formatDate(row.lastModifiedDate);
  },
});

export const requestType = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  key: "requestType",
  label: translateString("type"),
  plannedWidth: 175,
  priority: 0,
  valueRenderer: ({ value }) => {
    switch (value) {
      case RequestType.WarrantyClaim:
        return <LocalizedString id="warrantyClaim" />;

      case RequestType.TechRequest:
      case RequestType.OperationalSupportRequest:
        return <LocalizedString id="techRequest" />;

      case RequestType.CustomerSupportTicket:
        return <LocalizedString id="customerSupportTicket" />;

      case RequestType.SparePartClaim:
        return <LocalizedString id="sparePartClaim" />;

      default:
        return value;
    }
  },
});

export const warrantyEndDateForShipyard = ({
  enabled,
}: {
  enabled: boolean;
}): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  key: "warrantyEndDateForShipyard",
  label: translateString("request.warrantyEndDateForShipyard"),
  plannedWidth: 175,
  priority: 0,
  hideEmptyValues: true,
  alwaysHidden: true,
  isEnabled: () => enabled,
  valueRenderer: ({ value, row }) => {
    if (row.requestType !== RequestType.WarrantyClaim) return;
    return formatDate(String(value));
  },
});

export const suggestedParts = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "suggestedParts",
  label: translateString("request.suggestedSpareParts"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
  valueRenderer: ({ row }) => {
    return (row as IWarrantyClaim) && (row as IWarrantyClaim).suggestedParts.length > 0 ? (
      <Container $marginRight={6}>
        <ResponsiveTable
          rows={sortBy((row as IWarrantyClaim).suggestedParts, "description").map((part, id) => ({
            ...part,
            id: id.toString(),
            description: part.description || translateString("spareParts.descriptionMissing"),
          }))}
          columns={[
            {
              priority: 0,
              key: "description",
              plannedWidth: 200,
              label: translateString("spareParts.description"),
            },
            {
              priority: 0,
              key: "productId",
              plannedWidth: 200,
              label: translateString("spareParts.partNumber"),
            },
            {
              priority: 0,
              key: "quantity",
              plannedWidth: 200,
              label: translateString("spareParts.quantity"),
            },
          ]}
        />
      </Container>
    ) : undefined;
  },
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) => isEnabledForWarrantyClaim(row),
});

export const poNumber = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "poNumber",
  label: translateString("request.poNumber"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
});

export const wartsilaOrderNumber = (
  isPartsEnabled?: boolean
): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "wartsilaOrderNumber",
  label: translateString("request.orderNumber"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
  valueRenderer: ({ row }) => {
    const claim = row as ISparePartClaim;
    if (claim.orderSFId && claim.wartsilaOrderNumber) {
      const params: IRouteParams = { orderId: claim.orderSFId };
      const route: IRoute = getRoutes().SparePartsItem;
      return isPartsEnabled ? (
        <Link to={getPathWithParams(route, params)}>{claim.wartsilaOrderNumber}</Link>
      ) : (
        <>{claim.wartsilaOrderNumber}</>
      );
    } else if (!claim.orderSFId && claim.wartsilaOrderNumber) {
      return claim.wartsilaOrderNumber;
    } else {
      return "";
    }
  },
});

export const deliveryNumber = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "deliveryNumber",
  label: translateString("request.deliveryNumber"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
});

export const itemCount = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "itemCount",
  label: translateString("request.quantity"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
});

export const SparePartpart = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "part",
  label: translateString("request.part"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
});

export const claimReason = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "claimReason",
  label: translateString("request.claimReason"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
  valueRenderer: ({ row }) => {
    return (
      (row as ISparePartClaim).claimReason && translateString(`request.reason.${(row as ISparePartClaim).claimReason}`)
    );
  },
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) => isEnabledForSparePartClaim(row),
});

export const claimQuantity = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "claimQuantity",
  label: translateString("request.claimQuantity"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
});

export const accountName = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "creatorCompany",
  label: translateString("request.creatorCompany"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
});

export const createdByName = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "createdByName",
  label: translateString("request.creatorName"),
  plannedWidth: 200,
  priority: 1,
});

export const createdByPhone = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "createdByPhone",
  label: translateString("request.phone"),
  plannedWidth: 200,
  priority: 1,
  hideEmptyValues: true,
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) => isEnabledForSparePartClaim(row),
});

export const createdByEmail = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "createdByEmail",
  label: translateString("request.email"),
  plannedWidth: 200,
  priority: 1,
  hideEmptyValues: true,
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) => isEnabledForSparePartClaim(row),
});

export const wartsilaInitiatedClaim = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "wartsilaInitiatedClaim",
  label: translateString("request.wartsilaInitiated"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
  valueRenderer: ({ row }) => {
    return formatBoolean((row as IWarrantyClaimViewModel).wartsilaInitiatedClaim);
  },
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) => isEnabledForWarrantyClaim(row),
});

export const contacts = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "contacts",
  label: translateString("request.interestedPeople"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
  valueRenderer: ({ row }) => <InterestedPeople request={row} />,
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) =>
    isEnabledForWarrantyClaim(row) || isEnabledForTechRequest(row) || isEnabledForOperationalSupport(row),
});

export const external = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "external",
  label: translateString("request.external"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
  valueRenderer: ({ row }) => {
    return formatBoolean((row as ITechRequestViewModel).external);
  },
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) => isEnabledForTechRequest(row),
});

export const solutionPlan = (
  props: IWarrantyClaimListProps,
  context: INotificationContext
): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  hideEmptyValues: true,
  key: "solutionPlan",
  label: translateString("request.solutionPlan"),
  plannedWidth: 200,
  priority: 1,
  valueRenderer: ({ row }) => {
    return getSolutionPlanWithNotification(row, context, props);
  },
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) =>
    isEnabledForTechRequest(row) || isEnabledForOperationalSupport(row),
});

export const solutionPlanProvided = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  hideEmptyValues: true,
  key: "solutionPlanProvided",
  label: translateString("request.solutionPlanProvided"),
  plannedWidth: 200,
  priority: 1,
  valueRenderer: ({ row }) => {
    return (
      (row as ITechRequest).solutionPlanProvided && formatDate(new Date((row as ITechRequest).solutionPlanProvided))
    );
  },
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) =>
    isEnabledForTechRequest(row) || isEnabledForOperationalSupport(row),
});

export const solutionRating = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "solutionRating",
  label: translateString("request.rating"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
  valueRenderer: ({ row }) => {
    const rating = (row as ICustomerSupportTicket).solutionRating;
    return rating && getRatingNames()[rating];
  },
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) => isEnabledForCSTicket(row),
});

export const spcRating = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "rating",
  label: translateString("request.rating"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
  valueRenderer: ({ row }) => {
    const rating = (row as ISparePartClaim).rating;
    return rating && getRatingNames()[rating];
  },
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) => isEnabledForSparePartClaim(row),
});

const SolutionContainer = styled.div`
  & a {
    color: ${colors.primary.blue};
    cursor: pointer;
    text-decoration: underline;
  }
`;
type ImgNode = {
  name: string;
  attribs: {
    src: string;
    alt?: string;
  };
};

export const solutionText = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "solutionText",
  label: translateString("request.solutionText"),
  plannedWidth: 150,
  priority: 1,
  hideEmptyValues: true,
  valueRenderer: ({ row }) => {
    const solutionText = (row as ITechRequest).solutionText;
    const isFirefox = Boolean(navigator.userAgent.match(/firefox/i));
    const hasImages = Boolean(solutionText?.match("<img(.+?)>"));
    if (solutionText) {
      // @ts-ignore-next-line
      const parser = new Parser();
      // @ts-ignore-next-line
      const processNodeDefinitions = new ProcessNodeDefinitions();
      const solutionTextComponent = parser.parseWithInstructions(solutionText, () => true, [
        {
          replaceChildren: false,
          shouldProcessNode: (node: ImgNode) => isFirefox && hasImages && node.name === "img",
          processNode: (node: ImgNode) =>
            React.createElement(
              "a",
              { href: node.attribs.src, target: "_blank", rel: "noopener" },
              node.attribs.alt || translateString("request.openImage")
            ),
        },
        {
          shouldProcessNode: () => true,
          processNode: processNodeDefinitions.processDefaultNode,
        },
      ]);
      return (
        <>
          <SolutionContainer>{solutionTextComponent}</SolutionContainer>
        </>
      );
    }
    return "";
  },
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) =>
    isEnabledForTechRequest(row) || isEnabledForCSTicket(row) || isEnabledForOperationalSupport(row),
});

export const deliveries = (): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  label: "",
  priority: 1,
  key: "deliveries",
  plannedWidth: 100,
  alwaysHidden: true,
  isEnabled: (row) => Boolean(row.deliveries),
  renderer: ({ row, fetching }) => (
    <ResponsiveDeliveryTree
      deliveries={row.deliveries!}
      notifications={row.notifications as INotification<IDelivery>[]}
      fetching={fetching}
    />
  ),
});

export const attachments = (
  props: IWarrantyClaimListProps,
  state: IWarrantyClaimListComponentState,
  handleBusyChange: (busy: boolean) => void
): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  hideEmptyValues: true,
  key: "attachmentIds",
  label: translateString("request.attachments"),
  plannedWidth: 200,
  priority: 1,
  renderer: ({ row }) => {
    return (
      <div className={"support-onboarding-attachments"}>
        <Disclaimer>{translateString("request.attachments")}</Disclaimer>
        {
          // Disable attachment list/fetch for mock data
          !props.useOnboardingMockData && (
            <AttachmentList
              attachmentSource={row.requestType}
              isUploaderBusy={state.uploaderBusy}
              sourceId={row.id}
              user={props.user}
              isAttachmentUploadDisabled={
                row.requestType === Branch.SparePartClaim || isAttachmentUploadDisabled(row, props)
              }
              showTitle={true}
            />
          )
        }
        {!isAttachmentUploadDisabled(row, props) && (
          <AttachmentUploader
            iconColor={colors.primary.black}
            relatedId={row.id}
            files={[]}
            onFilesChanged={() => false}
            onBusy={handleBusyChange}
            requestType={row.requestType}
            onFileUploaded={onSuccessfulFileUpload(row)}
          />
        )}
      </div>
    );
  },
});

export const ticketComments = (
  props: IWarrantyClaimListProps,
  state: IWarrantyClaimListComponentState,
  registerCommentRefreshFn: (id: string, funct: () => void) => void,
  refreshComments: (id: string) => void,
  addCommentPlaceholder: (id: string, comment: IAppClaimComment) => void,
  popCommentPlaceholder: (id: string) => void
): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  alwaysHidden: true,
  key: "comments",
  label: translateString("commenting"),
  plannedWidth: 200,
  priority: 1,
  renderer: ({ row }) => {
    return (
      <div className={"support-onboarding-comments"}>
        <Disclaimer>{translateString("commenting")}</Disclaimer>
        <RequestComments
          isCommentingDisabled={isCommentingDisabled(row, props)}
          requestCanBeReopened={displayReopenButton(row) !== false}
          request={row}
          registerCommentFn={(funct: () => void) => registerCommentRefreshFn(row.id, funct)}
          refreshComments={() => refreshComments(row.id)}
          commentPlaceholders={state.commentPlaceholders[row.id]}
          addPlaceholder={(comment: IAppClaimComment) => addCommentPlaceholder(row.id, comment)}
          popPlaceholder={() => popCommentPlaceholder(row.id)}
        />
      </div>
    );
  },
  isEnabled: (row: ICombinedRequestWithNotificationsViewModel) =>
    isEnabledForTechRequest(row) ||
    isEnabledForCSTicket(row) ||
    isEnabledForWarrantyClaim(row) ||
    isEnabledForOperationalSupport(row),
});

export const closeClaim = (
  onConfirmClose: (claim: IWarrantyClaim) => void
): IResponsiveTableColumn<ICombinedRequestWithNotificationsViewModel> => ({
  key: "closeClaim",
  alwaysHidden: true,
  label: "",
  plannedWidth: 110,
  priority: 10,
  isEnabled: (row) =>
    isEnabledForWarrantyClaim(row) &&
    row.displayStatus !== RequestStatus.Draft &&
    row.displayStatus !== RequestStatus.Closed &&
    !row.pendingCustomerConfirmation,
  labelRenderer: ({ row }) => (row.confirmedByCustomer ? "" : translateString("request.closeClaim.disclaimer")),
  valueRenderer: ({ row }) =>
    row.confirmedByCustomer ? (
      <LocalizedString id="request.closeClaim.closed" />
    ) : (
      <CloseClaimButton onConfirmClose={() => onConfirmClose?.(row)} />
    ),
});

export const PreWrapText = styled.div`
  white-space: pre-line;
`;

export const isCommentingDisabled = (
  requestItem: ICombinedRequestWithNotificationsViewModel,
  props: IWarrantyClaimListProps
) => {
  return (
    isConfiguredToReadOnly(requestItem, props.serviceLimitations) ||
    props.select !== undefined ||
    requestItem.displayStatus === RequestStatus.Answered ||
    requestItem.displayStatus === RequestStatus.Draft ||
    requestItem.displayStatus === RequestStatus.Approved ||
    (requestItem.displayStatus === RequestStatus.Closed && requestItem.requestType !== Branch.WarrantyClaim) ||
    (requestItem.displayStatus === RequestStatus.Rejected && requestItem.requestType !== Branch.WarrantyClaim) ||
    requestItem.displayStatus === RequestStatus.SentToDistributor
  );
};

export const isConfiguredToReadOnly = (
  requestItem: ICombinedRequestWithNotificationsViewModel,
  serviceLimitations?: IServiceLimitations
) => {
  if (!serviceLimitations) {
    return false;
  }

  switch (requestItem.requestType) {
    case RequestType.WarrantyClaim:
      return serviceLimitations.warrantyCasesReadOnly;

    case RequestType.TechRequest:
      return serviceLimitations.externalTechRequestsReadOnly;

    case RequestType.CustomerSupportTicket:
      return false;

    case RequestType.SparePartClaim:
      return serviceLimitations.partsClaimsReadOnly;

    default:
      return false;
  }
};

export const isAttachmentUploadDisabled = (
  row: ICombinedRequestWithNotificationsViewModel,
  props: IWarrantyClaimListProps
) => {
  return (
    isConfiguredToReadOnly(row, props.serviceLimitations) ||
    props.select !== undefined ||
    row.displayStatus === RequestStatus.Answered ||
    (row.displayStatus === RequestStatus.Closed && row.requestType !== Branch.WarrantyClaim) ||
    row.displayStatus === RequestStatus.SentToDistributor
  );
};

export const isEnabledForTechRequest = (row: ICombinedRequestWithNotificationsViewModel) => {
  return row.requestType === Branch.TechRequest;
};

export const isEnabledForWarrantyClaim = (row: ICombinedRequestWithNotificationsViewModel) => {
  return row.requestType === Branch.WarrantyClaim;
};

export const isEnabledForCSTicket = (row: ICombinedRequestWithNotificationsViewModel) => {
  return row.requestType === Branch.CustomerSupportTicket;
};

export const isEnabledForSparePartClaim = (row: ICombinedRequestWithNotificationsViewModel) => {
  return row.requestType === Branch.SparePartClaim;
};

export const isEnabledForOperationalSupport = (row: ICombinedRequestWithNotificationsViewModel) => {
  return row.requestType === Branch.OperationalSupportRequest;
};

export const getSolutionPlanWithNotification = (
  item: ICombinedRequestWithNotificationsViewModel,
  notificationContext: INotificationContext,
  props: IWarrantyClaimListProps
) => {
  const solutionPlanNotifs = filterSolutionPlanNotifications(item.notifications, item);
  return (
    <>
      {solutionPlanNotifs.length > 0 && (
        <RowNotification
          onUnmount={() =>
            props.discardAndSetNotificationAsRead(
              solutionPlanNotifs.map((notif) => notif.id),
              notificationContext
            )
          }
        />
      )}
      {(item as ITechRequest).solutionPlan}
    </>
  );
};

const onSuccessfulFileUpload = (item: ICombinedRequestWithNotificationsViewModel) => () => {
  if (item.requestType === Branch.TechRequest) {
    new APIFetch("requests/techrequests", "filelogger").get(item.id);
  } else if (item.requestType === Branch.CustomerSupportTicket) {
    new APIFetch("requests/customer-support-tickets", "filelogger").get(item.id);
  } else if (item.requestType === Branch.OperationalSupportRequest) {
    new APIFetch("requests/operationalsupportrequests", "filelogger").get(item.id);
  }
};
