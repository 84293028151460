import { IAccount } from "online-services-types";
import * as React from "react";
import { components, OptionProps } from "react-select";
import { APIFetchStatus } from "src/APIFetch";
import { DropdownStyle, ISelectObject, Select } from "src/design-system/Select/Select";
import { Disclaimer, IDisclaimerTheme } from "src/design-system/Tokens/typography";
import { APIResource } from "src/redux/APIResource";
import { getRoutes, redirectBrowser } from "src/routes";

export interface IAccountSelectorOwnProps {
  dropdownStyle?: DropdownStyle;
  disableRedirect?: boolean;
}

export interface IAccountSelectorReduxStateProps {
  accounts: APIResource<IAccount>;
  showAccounts: boolean;
}

export interface IAccountSelectorDispatchProps {
  getAccounts(): void;
  selectCurrentAccount(accountId: string): void;
}

export type IAccountSelectorComponentProps = IAccountSelectorOwnProps &
  IAccountSelectorDispatchProps &
  IAccountSelectorReduxStateProps;

export class AccountSelectorComponent extends React.Component<IAccountSelectorComponentProps> {
  public async componentDidMount() {
    if (!this.props.accounts?.data?.length) {
      this.props.getAccounts();
    }
  }

  public render() {
    if (!this.props.showAccounts) {
      return null;
    }

    return (
      <Select<IAccount>
        name="account"
        options={this.props.accounts.data}
        getOptionLabel={(option: ISelectObject<IAccount>) => option.item.name}
        getOptionValue={(option: ISelectObject<IAccount>) => option.item.id}
        dropdownStyle={this.props.dropdownStyle ?? DropdownStyle.Transparent}
        value={this.getSelectedAccount()}
        onChange={this.onSelect}
        isLoading={this.props.accounts.status === APIFetchStatus.Busy}
        components={{
          Option: (optProps: OptionProps<ISelectObject<IAccount>, boolean>) => (
            <components.Option {...optProps}>
              <div>{optProps.data.item?.name}</div>
              {optProps.data.item?.additionalName && (
                <Disclaimer forcedTheme={optProps.isSelected ? IDisclaimerTheme.Light : IDisclaimerTheme.Dark}>
                  {optProps.data.item.additionalName}
                </Disclaimer>
              )}
            </components.Option>
          ),
        }}
      />
    );
  }

  private readonly onSelect = async (item: IAccount) => {
    const selectedAccount = this.getSelectedAccount();
    if (selectedAccount && selectedAccount.id === item.id) {
      return;
    }

    this.props.selectCurrentAccount(item.id);
    if (!this.props.disableRedirect) {
      redirectBrowser(getRoutes().Index);
    }
  };

  private readonly getSelectedAccount = () => (this.props.accounts.data || []).find((account) => account.selected);
}
